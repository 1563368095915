<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        Selecciona el estudio en que quieres practicar: <!-- {{showEstudios}} --><span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
        <br>
        <br>
        <div class="row">
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;" :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Tobalaba')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Tobalaba</span>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
              <br />
              <br />
              <div style="width: 100%;" class="badge bg-warning text-dark">
                Strip Center Tobalaba / Metro Bilbao<br />
                Av. Tobalaba 4507 local 7 y 8, Ñuñoa
              </div>
            </button>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;" :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Vitacura')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Vitacura</span>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
              <br />
              <br />
              <div  style="width: 100%;"  class="badge bg-warning text-dark">
                Centro Comercial Plaza San Pío<br />
                Av. Vitacura 5255, esquina Pío XI
              </div>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
            </button>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;" :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Quinchamali')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Quinchamalí</span>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
              <br />
              <br />
              <div  style="width: 100%;"  class="badge bg-warning text-dark">
                Av. Las Condes 14151, Local 4<br />
                esquina Av Quinchamalí
              </div>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
            </button>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;"  :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Nunoa')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Ñuñoa</span>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
              <br />
              <br />
              <div  style="width: 100%;"  class="badge bg-warning text-dark">
                Av. Chile España 26<br />
                a pasos del metro Chile España
              </div>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
            </button>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;" :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Puertomontt')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Puerto Montt</span>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
              <br />
              <br />
              <div  style="width: 100%;"  class="badge bg-warning text-dark">
                Casa del Diamante<br />
                Luis Ross 460, Puerto Montt
              </div>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
            </button>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;" :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Puertovaras')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Puerto Varas</span>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
              <br />
              <br />
              <div style="width: 100%;" class="badge bg-warning text-dark">
                Mall Paseo Puerto Varas<br />
                Local Subsuelo
              </div>
              <!-- <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span> -->
            </button>
          </div>
          <div class="col-sm-4 col-md-4 col-lg-3 select-studio">
            <button style="width: 100%;" :disabled="loading" class="btn btn-primary" @click.prevent="setStudio('BYCYOGA_Valdivia')">
              <br />
              <span style="font-size: 32px; font-weight: 400;">Valdivia</span>
              <br />
              <br />
              <div style="width: 100%;" class="badge bg-warning text-dark">
                Av. Carlos Anwandter #860<br />
                a pasos del terminal de buses Valdivia
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')

export default {
  name: 'EstudiosBetaComponent',
  data () {
    return {
      data: [],
      loading: false,
      email: '',
      password: '',
      nombre: '',
      apellido: '',
      rut: '',
      telefono: '',
      showEstudios: false,
      createUser: false
    }
  },
  mounted () {},
  methods: {
    async setStudio (studioCode) {
      const self = this
      self.loading = true
      // console.log('>>> this????', localStorage.getItem('bycyoga-jwt'))

      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'set-studio', payload: { studioCode } }, { headers })
        // console.log('>>> recibo:', response.data)
        if (response && response.data && response.data.result === true) {
          // temporal para que no salgan los avisos de tyc
          // @todo descontectar
          // if (process.env.VUE_APP_ENV === 'production') response.data.alumnoData.tyc = true
          // response.data.alumnoData.tyc = true
          self.$store.commit('setAlumnoData', response.data.alumnoData)
          self.$store.commit('setStudio', studioCode)
          self.$router.push({ name: 'BuscarClases', params: { } })
          // self.$router.push({ name: 'MisClases', params: { } })
        } else {
          self.$store.commit('setError', true)
          self.loading = false
        }
      } catch (e) {
        self.$store.commit('setError', true)
        self.loading = false
      }
    }
  }
}
</script>
