<template>
  <div v-if="loading" class="center">
    <i class="fas fa-circle-notch fa-spin"></i> cargando datos...
  </div>
  <div v-else>
    <div class="center">
      <!-- mis datos -->
      <div v-for="clase of data" v-bind:key="clase._id">
        {{clase.computed.appsBycyogaAlumno.nombre}} {{clase.computed.appsBycyogaAlumno.apellido}}<br>
        {{clase.computed.appsBycyogaAlumno.rut}}<br>
        {{clase.computed.appsBycyogaAlumno.email}}<br>
        {{clase.computed.appsBycyogaAlumno.telefono}}<br><br>
        <!-- {{nombre}} -->
        <div v-if="editData">
          <br>
          <div class="row">
            <div class="offset-lg-4 col-lg-4">
              <div>Nombre: <input class="form-control" v-model="nombre" placeholder="nombre" /></div>
              <div><br>Apellido: <input class="form-control" v-model="apellido" placeholder="apellido" /></div>
              <div><br>Rut (sin puntos y con guión): <input class="form-control" v-model="rut" placeholder="rut" /></div>
              <div><br>Teléfono: <input class="form-control" v-model="telefono" placeholder="telefono" /></div>
              <br>
              <button class="btn btn-success" @click.prevent="guardarDatos">guardar datos</button>
            </div>
          </div>
        </div>
        <button v-else class="btn btn-primary" @click.prevent="editar">editar mis datos</button>
      </div>
      <div v-if="$store.state.alumnoData.tyc !== true">
        <br>
        <br>
        <a href="#" @click.prevent="abrirTYC">Ver Términos y Condiciones haciendo click acá</a>
        <br>
        <br>
        <button class="btn btn-primary" @click="aceptarTYC">Aceptar Términos y Condiciones</button>
      </div>
      <br>
      <br>
      <!-- ¿Quieres actualizar tus datos? <router-link to="/carro">llena este formulario</router-link>
      <br>
      <br> -->
      <div v-for="clase of data" v-bind:key="clase._id">
        <span style="font-weight: bold;">Crédito disponible: ${{clase.saldoMonto}}</span><br>
        Recuerda ocupar tus créditos antes de que expiren:
        <div v-for="saldo of clase.saldoMontoData" v-bind:key="saldo">${{saldo.monto}} que expiran <span style="text-decoration: none; font-weight: 500">{{formatSaldoExp(saldo.expirationDate)}}</span></div>
        <!-- Clases: {{clase.saldoClases}}<br> -->
        <!-- Clases Challenge (60): {{clase.saldoChallenge60}}<br> -->
        <!-- Clases Challenge (180): {{clase.saldoChallenge180}}<br> -->
      </div>
      <br>
      ¿Quieres abonar dinero a tu cuenta? <router-link to="/carro">simplemente ingresa el monto acá (en abono)</router-link><br>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
const moment = require('moment-timezone')
// de utils
/**
 * formatear fecha en elapsed time o remaining
 */
const formatDateChileElapsed = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').fromNow()
}
const formatDateChileDate = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').format('dddd D MMMM, YYYY - H:mm')
}

export default {
  name: 'MisClases',
  props: {
    msg: String
  },
  data () {
    return {
      VUE_APP_API_SERVER: process.env,
      data: [],
      loading: false,
      showBackToLogin: false,
      editData: false,
      nombre: '',
      apellido: '',
      // email: '',
      rut: '',
      telefono: ''
    }
  },
  mounted () {
    this.loadAuthContent()
  },
  computed: {},
  methods: {
    async guardarDatos () {
      const self = this
      const { nombre, apellido, rut, telefono } = self

      self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      const payload = { nombre, apellido, rut, telefono }
      try {
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'update-my-user', payload }, { headers })
        if (response && response.data) {
          console.log('>>> recibo', response.data)
          self.editData = false
          self.loadAuthContent()
          window.alert('Datos actualizados')
        }
        self.loading = false
      } catch (e) {}
    },
    async aceptarTYC () {
      const self = this
      self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'accept-terms-and-conditions' }, { headers })
        if (response && response.data) {
          console.log('>>> recibo', response.data)
          // @todo revisar si esto es necesario o no
          if (process.env.VUE_APP_ENV === 'production') self.$store.state.alumnoData.tyc = true // no se si esto está bien o no
          if (process.env.VUE_APP_ENV === 'testing') self.$store.state.alumnoData.tyc = true // no se si esto está bien o no
          window.alert('Términos y condiciones aceptados!\n\nRevisa tu correo (si no llega pronto revisa en no deseados/spam).')
        }
        self.loading = false
      } catch (e) {}
    },
    abrirTYC () {
      window.open('/tyc.html', '_blank')
    },
    editar () {
      this.editData = true
    },
    formatSaldoExp (ts) {
      return formatDateChileElapsed(ts) + ' (' + formatDateChileDate(ts) + ')'
    },
    algo (clase) {
      return clase.computed.appsBycyogaClaseInstance.computed.appsBycyogaClase.text + ' (' + formatDateChileElapsed(clase.computed.appsBycyogaClaseInstance.date) + ')'
    },
    getBoxName (clase) {
      return clase.computed.appsBycyogaBox.text
    },
    // cancelClass (claseId) {
    //   console.log('>>> about to cancel class with id', claseId)
    // },
    loadAuthContent () {
      this.loading = true
      const self = this
      self.$store.commit('setError', false)
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      axios
        .post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'mis-datos' }, { headers })
        .then(response => {
          self.loading = false
          if (response && response.data && response.data.data) {
            self.data = response.data.data
            // if (response.data.data)
            if (Array.isArray(self.data) && self.data.length > 0) {
              self.data.forEach(d => {
                if (d.computed && d.computed.appsBycyogaAlumno) {
                  const { nombre, apellido, rut, telefono } = d.computed.appsBycyogaAlumno
                  self.nombre = nombre
                  self.apellido = apellido
                  self.rut = rut
                  self.telefono = telefono
                }
              })
            }
          }
        })
        .catch(e => {
          self.loading = false
          self.$store.commit('setError', true)
        })
    }
  }
}
</script>
