import axios from 'axios'
import qs from 'qs'
// eslint-disable-next-line camelcase
import { jwtDecode } from 'jwt-decode'

// @deprecated
const verifyLogin = async (storeObject, data) => {
  // console.log('>>>> entering verifyLogin')
  // try {
  //   if (window) {
  //     const jwt = window.localStorage.getItem('jwt')
  //     const jwtExp = window.localStorage.getItem('jwt-exp')
  //     storeObject.commit('setJwt', jwt)
  //     storeObject.commit('setJwtExp', jwtExp)
  //   }
  // } catch (e) {}
}
const storeLoginData = async (storeObject, jwt) => {
  await storeObject.commit('setJwt', jwt)
  try {
    // save expiration
    const decoded = jwtDecode(jwt)
    // this.$store.commit('setJwtExp', decoded.exp * 1000)
    await storeObject.commit('setJwtExp', decoded.exp * 1000)
  } catch (e) { }

  if (window) {
    window.localStorage.setItem('bycyoga-jwt', jwt)
    try {
      const decoded = jwtDecode(jwt)
      // save expiration
      window.localStorage.setItem('bycyoga-jwt-exp', decoded.exp * 1000)
    } catch (e) { }
  }
}

const doVerify = async (storeObject, data) => {
  try {
    const result = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy-open', { action: 'account-check', payload: data })
    if (result.data) {
      return result.data
    } else {
      return { result: false }
    }
  } catch (e) {
    return { result: false, reason: e }
  }
}

const doReset = async (storeObject, data) => {
  try {
    const result = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy-open', { action: 'password-reset', payload: data })
    if (result && result.data) {
      return { result: true, data: result.data }
    } else {
      return { result: false }
    }
  } catch (e) {
    return { result: false, reason: e }
  }
}

const doRegister = async (storeObject, data) => {
  try {
    const result = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy-open', { action: 'user-register', payload: data })
    if (result && result.data) {
      return { result: true, data: result.data }
    } else {
      return { result: false }
    }
  } catch (e) {
    return { result: false, reason: e }
  }
}

// @deprecated
const doLogin = async (storeObject, data) => {
  try {
    const loginResult = await axios.post(process.env.VUE_APP_API_SERVER + '/authentication', data)
    if (loginResult && loginResult.data && loginResult.data.accessToken) {
      const jwt = loginResult.data.accessToken
      storeLoginData(storeObject, jwt)
      return { result: true, jwt }
    } else {
      return { result: false }
    }
  } catch (e) {
    return { result: false, reason: e }
  }
}

const doSetStudio = async (storeObject, data) => {
  try {
    console.log('>>> inside of doSetStudio', storeObject.getters.getJwt)
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    const result = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'set-studio', payload: data })
    if (result && result.data) {
      return { result: true, data: result.data }
    } else {
      return { result: false }
    }
  } catch (e) {
    return { result: false, reason: e }
  }
}

const create = async (storeObject, data) => {
  const { model, payload } = data
  // borrar cosas antes
  delete payload.computed
  delete payload.joined
  try {
    // axios.defaults.headers.common.Authorization = 'Bearer ' + window.localStorage.getItem('jwt')
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    const createResult = axios.post(process.env.VUE_APP_API_SERVER + '/' + model, payload)
    return createResult
  } catch (e) {}
}

const get = async (storeObject, data) => {
  const { model, id } = data
  try {
    // axios.defaults.headers.common.Authorization = 'Bearer ' + window.localStorage.getItem('jwt')
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.get(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id)
  } catch (e) {}
}

const remove = async (storeObject, data) => {
  const { model, id } = data
  try {
    // axios.defaults.headers.common.Authorization = 'Bearer ' + window.localStorage.getItem('jwt')
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.delete(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id)
  } catch (e) {}
}

const find = async (storeObject, data) => {
  const { model, query } = data
  // console.log('>>> recibo', storeObject.state)
  try {
    // axios.defaults.headers.common.Authorization = 'Bearer ' + window.localStorage.getItem('jwt')
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    let findResult = await axios.get(process.env.VUE_APP_API_SERVER + '/' + model, {
      params: query,
      paramsSerializer: function (params) {
        return qs.stringify(params, { arrayFormat: 'brackets' })
      }
    })
    if (findResult && findResult.data) findResult = findResult.data // fix para axios

    return findResult
  } catch (e) {}
}

const update = async (storeObject, data) => {
  const { model, payload, id } = data
  // borrar cosas antes
  delete payload.computed
  delete payload.joined
  // try {
  // axios.defaults.headers.common.Authorization = 'Bearer ' + window.localStorage.getItem('jwt')
  axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
  // return axios.patch(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id, payload) // patch
  return axios.put(process.env.VUE_APP_API_SERVER + '/' + model + '/' + id, payload) // update
  // } catch (e) {
  //   throw new Error('Cannot update' + JSON.stringify(e))
  // }
}

const proxy = async (storeObject, data) => {
  const { payload } = data
  const { action } = payload
  let service = ''
  if (action === 'commitTransaction') {
    service = 'apps-bycyoga-service-commit-transaction-stash'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'deleteClassInstance') {
    service = 'apps-bycyoga-service-delete-class-instance'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'getAlumnos') {
    service = 'apps-bycyoga-service-clase-alumnos-get'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.put(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  } else if (action === 'generarClases') {
    service = 'apps-bycyoga-service-generar-clases'
    axios.defaults.headers.common.Authorization = 'Bearer ' + storeObject.getters.getJwt
    return axios.get(process.env.VUE_APP_API_SERVER + '/' + service, payload) // update
  }
}

export {
  verifyLogin,
  doLogin,
  doRegister,
  doSetStudio,
  doReset,
  doVerify,
  find,
  get,
  create,
  remove,
  update,
  storeLoginData,
  proxy
}
