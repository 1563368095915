<template>
  <div class="container">
    <div class="row">
      <div class="col-3" style=""></div>
      <div class="col-6" style="text-align: center;margin-top: 10px;"><router-link class="linker" to="/"><img src="@/assets/img/logo.svg" alt="Logo de Bikram" style="height: 50px;"> </router-link><br><br></div>
      <div class="col-3" style=""></div>
    </div>
    <!-- <div class="row d-xl-none d-lg-none">
      <div class="col-3" style=""></div>
      <div class="col-6" style="text-align: center;"><a href="https://www.bycyoga.cl/"><img src="@/assets/img/logo.svg" alt="Logo de Bikram" style="height: 50px;"></a><br><br></div>
      <div class="col-3" style=""></div>
    </div> -->
<!--     <div class=" d-none d-lg-block d-xl-block">
      <div class="row container-superior">
        <div class="col" style=""><a href="https://www.bycyoga.cl/"><img src="@/assets/img/logo.svg" alt="Logo de Bikram" style="height: 50px;"></a></div>
        <div class="col link-superiorQ"><a class="linker" href="https://bycyoga.cl/estudios/">Estudios</a> | <a class="linker" href="https://bycyoga.cl/preguntas_frecuentes/">Preguntas Frecuentes</a> | <a class="linker" href="https://bycyoga.cl/contacto/">Contacto</a></div>
      </div>
    </div> -->
  </div>
  <template v-if="$route.name !== 'Home' && $route.name !== 'Login' && $route.name !== 'Help' && $route.name !== 'Register'">
    <div class="row center" v-if="$store.state.studio">
      <div class="col">
        <div class="alert alert-success fade show" role="alert">
          <strong>¡Hola {{$store.state.alumnoData.nombre}} {{$store.state.alumnoData.apellido}}!</strong><br> ahora estás en el estudio {{getStudioName($store.state.studio)}}
          <!-- <br><code>{{$store.state.alumnoData}}</code> -->
        </div>
      </div>
    </div>
    <div class="row center" v-if="$store.state.studio && $store.state.alumnoData && $store.state.alumnoData.tyc !== true">
      <div class="col">
        <div class="alert alert-danger fade show" role="alert">
          <strong>¡Atención!</strong><br>
          <strong>Todavía falta un paso importante para termines tu proceso de inscripción y puedas practicar en nuestros estudios.</strong>
          <br><br>
          <span style="text-decoration: underline;">Es necesario que aceptes los términos y condiciones:</span><br>
          Para hacerlo sigue los siguientes pasos:<br>
          1) anda a la sección "Cuenta" <br>
          2) click en "editar mis datos"<br>
          3) modifica tus datos para que estén actualizados<br>
          4) click en botón verde "guardar datos"<br>
          5) una vez guardados los datos haz click en botón azul "Aceptar Términos y Condiciones"<br>
          6) recibirás un correo con tu copia<br>
        </div>
      </div>
    </div>
  </template>
  <template v-else>
    <div class="row center" v-if="showWarning">
      <div class="col">
        <!-- <div class="alert alert-warning fade show" role="alert">
          <strong>¡Atención alumnos!</strong><br> Debido a que tenemos un nuevo sistema puede ser que no estés registrado o no sepas tu clave.<br>Por favor visita <router-link class="linker" to="/help">esta sección si necesitas revisar tu situación.</router-link>
        </div> -->
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
          <h4 class="alert-heading">¡Atención alumnos!</h4>
          <!-- <p> -->
            Debido a que tenemos un nuevo sistema puede ser que no estés registrado o no sepas tu clave.
          <!-- </p> -->
          <hr>
          <p class="mb-0"><router-link class="linker" to="/help">Haz click aquí si necesitas revisar tu situación.</router-link></p>
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click.prevent="hideWarning"></button>
        </div>
      </div>
    </div>
  </template>
  <div class="row center" v-if="$store.getters.getError">
    <div class="col">
      <div class="alert alert-warning fade show" role="alert">
        <strong>¡Hubo un problema al cargar los datos!</strong><br> Por favor reintenta o<router-link class="linker" to="/login" @click="$store.commit('setError', false)">ingresa tu clave acá</router-link>
      </div>
    </div>
  </div>

  <!-- <div class="row center" v-if="$store.state.studio">
    <div class="col">
      <div class="alert alert-success fade show" role="alert">
        <strong>¡Hola {{$store.state.alumnoData.nombre}} {{$store.state.alumnoData.apellido}}!</strong><br> ahora estás en el estudio {{getStudioName($store.state.studio)}}
      </div>
    </div>
  </div> -->
  <template v-if="$store.state.studio" />
  <template v-else>
    <div class="row center" v-if="$route.name !== 'Home' && $route.name !== 'Login' && $route.name !== 'Help' && $route.name !== 'Register'">
      <div class="col">
        <div class="alert alert-warning fade show" role="alert">
          <router-link class="linker" to="/estudios">recuerda seleccionar un estudio acá</router-link>
        </div>
      </div>
    </div>
  </template>

  <div id="nav">
    <!-- <router-link class="linker" to="/login">Ingreso</router-link> -->
    <!-- <router-link class="linker" to="/estudios">Selecciona estudio</router-link> -->
    <!-- <router-link class="linker" to="/estudios">Estudios</router-link> -->
    <template v-if="$store.state.studio">
      <router-link class="linker" to="/buscar-clases"><span class="">Comprar <i class="fas fa-store"></i></span></router-link> |
      <router-link class="linker" to="/carro">Carro</router-link> |
      <router-link class="linker" to="/mis-clases">Mis clases</router-link>
    </template>
  </div>
  <!-- algo: {{studio}} -->
  <!-- dos: {{this.$store.state.studio}} -->
  <router-view/>

  <template v-if="$store.state.studio && $route.name !== 'Home' && $route.name !== 'Login' && $route.name !== 'Help' && $route.name !== 'Register'">
    <br>
    <br>
    <div class="row">
      <div class="col center">
        <div class="center" v-if="$route.name !== 'Carro' && $route.name !== 'Estudios' && $route.name !== 'Home'">
          <br />
          <router-link to="/carro"><div class="btn btn-success"><i class="fas fa-award"></i>&nbsp;&nbsp;obtén tu programa acá&nbsp;&nbsp;<i class="fas fa-award"></i></div></router-link>
          <template v-if="$store.state && $store.state.alumnoData && $store.state.alumnoData.esFirstTimer === true">
            <br />
            <router-link to="/carro">
              <div class="btn btn-success btn-smQQ" style="margin-top: 3px;">
                <!-- <i class="fas fa-award"></i>&nbsp;&nbsp; -->
                obtén tu First Timer acá
                <!-- &nbsp;&nbsp;<i class="fas fa-award"></i> -->
                <br />
                <span style="font-size: 11px;"><i class="fas fa-award"></i> $21.000</span>
              </div>
            </router-link>
          </template>
        </div>
        <br>
        <router-link class="linker" to="/estudios">Selecciona estudio</router-link> |
        <router-link class="linker" to="/mis-datos">Cuenta</router-link> |
        <!-- <router-link class="linker" to="/becas">Becas</router-link> | -->
        <a href="#" class="linker" @click.prevent="logout" to="/">Cerrar sesión</a>
      </div>
    </div>
  </template>

  <div class="row" style="background: #002891; color: white; margin-top: 50px">
    <div class="col-3 footer">
      <img src="@/assets/iconos/facebook.svg" class="icon-social" />
      <img src="@/assets/iconos/instagram.svg" class="icon-social" />
      <img src="@/assets/iconos/zoom.svg" class="icon-social" />
    </div>
    <div class="col-5 footer" style="margin-top: 20px;"><span style="font-weight: bold;">© BYC.</span> Todos los derechos reservados.</div>
    <div class="col-3 footer" style="font-size: 26px;">bycyoga.cl</div>
  </div>
  <!-- <div class="row" style="margin-top: 17px">
    <div class="col center">
      <router-link to="/carro">
        <img src="@/assets/img/membresias.jpeg" alt="Logo de Bikram" style="max-width: 500px;">
      </router-link>
    </div>
  </div> -->
</template>

<script>
import axios from 'axios'
// import JuiciosElement from './JuiciosElement'
// import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
// dashkit theme
// import '@/assets/themes/dashkit/css/theme.bundle.css'
import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/themes/dashkit/css/libs.bundle.css'
// import '@/assets/css/estilos.css'
import '@/assets/css/estilos2.css'

export default {
  name: 'homePage',
  // components: { JuiciosElement, PulseLoader },
  data () {
    return {
      VUE_APP_API_SERVER: process.env,
      data: [],
      loading: false,
      showBackToLogin: false,
      studio: 'some',
      showWarning: false
    }
  },
  mounted () {
    document.title = 'Bycyoga - Alumnos'
    // console.log('>>> mounted general', this.VUE_APP_API_SERVER)
    this.warmup()
    // this.startTimer()
    // this.studio = this.$store.commit('getStudio')
    // this.studio = this.$store.getters.getStudio()

    // cada x segundos un request que me dice en que empresa estoy parado y como me llamo
  },
  methods: {
    logout () {
      const self = this
      // const { email, password } = self
      // self.loading = true
      // self.showWarning = false

      try {
        self.$store.dispatch('storeLoginData', null)
        self.$router.push({ name: 'Home', params: { } })
        return { result: true }
      } catch (e) {
        self.showWarning = true
        self.showWarningText = `<br>Por favor revisa tu email y tu clave y vuelve a intentar<br><span style="font-weight: bold">REF:</span> ${e.message}`
        return { result: false, reason: e }
      } finally {
        self.loading = false
      }
    },
    startTimer () {
      const self = this
      self.warmup()
      self.intervalId = setInterval(function () {
        self.warmup()
      }, 1000 * 30 * 1)
    },
    warmup () {
      axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy-open', { action: 'warmup' })
    },
    hideWarning () {
      this.showWarning = false
    },
    getStudioName (code) {
      if (code === 'BYCYOGA_Tobalaba') return 'Tobalaba'
      if (code === 'BYCYOGA_Vitacura') return 'Vitacura'
      if (code === 'BYCYOGA_Quinchamali') return 'Quinchamalí'
      if (code === 'BYCYOGA_Nunoa') return 'Ñuñoa'
      if (code === 'BYCYOGA_Puertomontt') return 'Puerto Montt'
      if (code === 'BYCYOGA_Puertovaras') return 'Puerto Varas'
      if (code === 'BYCYOGA_Valdivia') return 'Valdivia'
    }
  }
}
</script>

<style lang="scss">

.center {
  text-align: center !important;
}
#nav {
  padding-top: 15px !important;
  text-align: center;
}
.container-superior {
  margin: 30px;
}
.link-superior {
  padding-top: 25px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
      font-weight: bold !important;
    }
  }
}
</style>
