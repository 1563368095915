import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { FontAwesomeIcon } from '@/plugins/font-awesome'

// createApp(App).use(store).use(router).mount('#app')

const app = createApp(App)
// eslint-disable-next-line vue/multi-word-component-names
app.component('fa', FontAwesomeIcon)
app
  .use(store)
  .use(router)
  .mount('#app')
