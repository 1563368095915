<template>
  <!-- {{$store.state.enableEspaciosVitacura === true}} -->
<template v-if="$store?.state?.studio">
  <div v-if="transactionError" class="container center alert alert-danger alert-dismissible fade show" role="alert">
    <strong>Ocurrío un error inesperado al conectarnos con Transbank!</strong>
    <br>Por favor <a @click.prevent="reload">haz click acá para recargar esta página</a> y volver a intentar.
    <br>
    <br><strong>REF:</strong> {{transactionErrorText}}
  </div>
  <div v-if="loading" class="center">
    <i class="fas fa-circle-notch fa-spin"></i> cargando datos...
  </div>
  <div v-else>
    <div class="col-sm-12 col-xl-8 offset-xl-2">
      <table class="table table-sm table-nowrap">
        <thead>
          <tr>
            <th>Item</th>
            <th>Monto</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="clase of data" v-bind:key="clase._id">
            <td class="text-end">{{formatDate(clase)}}</td>
            <td>${{clase.precioSpot}}</td>
            <td>
              <button :disabled="loading" class="btn btn-warning btn-sm" @click.prevent="cancelClass(clase._id)">
              cancelar <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
              </button>
            </td>
          </tr>
          <tr v-if="total.clases > 0">
            <td style="font-weight: bold;" class="text-end">Subtotal</td>
            <td style="font-weight: bold;">${{total.subtotal}}</td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">Saldo disponible</td>
            <td>${{total.saldoExistente}}</td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">Abono</td>
            <td><input class="form-control" v-model="abono"></td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">Programas Limitados</td>
            <td>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_630" style="margin-bottom: 1px" @click.prevent='setMembresia(630)'>6 CLASES EN UN MES<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $115.000</span></div>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_830" style="margin-bottom: 1px" @click.prevent='setMembresia(830)'>8 CLASES EN UN MES<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $150.000</span></div>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_1230" style="margin-bottom: 1px" @click.prevent='setMembresia(1230)'>12 CLASES EN UN MES<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $210.000</span></div>
              &nbsp;
            </td>
            <td></td>
          </tr>

          <tr v-if="$store?.state?.alumnoData?.esFirstTimer === true">
            <td class="text-end">Programas Introductorios</td>
            <td>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_707" @click.prevent='setMembresia(707)'>First Timer / Primera vez. Una semana ilimitada<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $35.000</span></div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">Programas Ilimitados</td>
            <td>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_30301" style="margin-bottom: 1px" @click.prevent='setMembresia(30301)'>1 MES ILIMITADO<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $320.000</span></div>
              <template v-if="deSantiago">
                &nbsp;
              </template>
              <template v-else>
                &nbsp;
                <div class="btn btn-success btn-sm" id="PLAN_60602" style="margin-bottom: 1px" @click.prevent='setMembresia(60602)'>2 meses ilimitado (S) box<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $339.000</span></div>
              </template>
            </td>
            <td></td>
          </tr>
          <tr v-if="$store?.state?.studio === 'BYCYOGA_Vitacura'">
            <td class="text-end">Programas Vitacura</td>
            <td>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_8006001" style="margin-bottom: 1px" @click.prevent='setMembresia(8006001)'>Tercera Edad y Estudiante  1 MES 13:30 y 16:15<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $75.000</span></div>
              &nbsp;
              <div class="btn btn-success btn-sm" id="PLAN_8006002" style="margin-bottom: 1px" @click.prevent='setMembresia(8006002)'>General 1 MES 13:30 y 16:15<br><span style="font-size: 11px;"><i class="fas fa-award"></i> $162.000</span></div>
              <br>
              * Tercera edad 60 años en adelante<br>
              * Estudiantes SUB 23<br>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end"></td>
            <td>
              <div>
                <a href="#" @click.prevent="abrirTYCMembresia">Ver Términos y Condiciones acá</a>
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="text-end">Total a pagar</td>
            <td>
              ${{total.total}}
              <!-- <template v-if="$store?.state?.studio === 'BYCYOGA_Vitacura' && $store?.state?.enableEspaciosVitacura === true">
                <span class="badge bg-warning text-dark">los espacios numerados tienen un descuento de un 10% que se aplicará al momento de pagar en Transbank</span>
              </template> -->
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!mostrarTyc" class="row">
      <div class="offset-3 col-6 center" style="font-weight: bold;">
        <button v-if="total.total > 0" :disabled="loading" class="btn btn-primary" @click.prevent='preCheckout'>siguiente paso <i class="fas fa-forward"></i></button>
        <button v-else :disabled="loading" class="btn btn-primary" @click.prevent='preCheckout2'>siguiente paso <i class="fas fa-forward"></i></button>
      </div>
    </div>
    <div class="center" v-else>
      <h4><span class="btn btn-warning">Para continuar debes leer y aceptar los términos y condiciones</span></h4>
      <br>
      <div class="row">
        <a href="#" @click.prevent="abrirTYC">Ver Términos y Condiciones haciendo click acá</a>
      </div>
      <br>
      <form v-if="total.total > 0" ref="myForm" method="post" :action="webpayDataUrl">
        <!-- <input type="hidden" id="token" name="token_ws" value.sync="webpayData.token" /> -->
        <input type="hidden" id="token" name="token_ws" v-model="webpayDataToken" />
        <button type="submit" class="btn btn-primary">Aceptar TYC y continuar con el pago (${{total.total}}) <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span></button>
        <!-- <template v-if="$store?.state?.studio === 'BYCYOGA_Vitacura' && $store?.state?.enableEspaciosVitacura === true">
          <br /><span class="badge bg-warning text-dark">los espacios numerados tienen un descuento de un 10% que se aplicará al momento de pagar en Transbank</span>
        </template> -->
        <span v-if="isTesting !== 'production'"><br />DEBUG DATA: {{webpayDataUrl}} / {{webpayDataToken}}<br></span>

        <!-- <button @click.prevent='checkout' class="btn btn-primary">Aceptar TYC y continuar (pago ${{total.total}}) <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span></button> -->
      </form>
      <button v-else :disabled="loading" class="btn btn-primary" @click.prevent='preCheckout'>comprar clase (pago $0)</button>
    </div>
  </div>
</template>
</template>

<script>
const axios = require('axios')
const moment = require('moment-timezone')
// de utils
/**
 * formatear fecha en elapsed time o remaining
 */
const formatDateChileElapsed = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').fromNow()
}
const formatDateChileDate = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').format('dddd D MMMM, YYYY - H:mm')
}
const formatDateChileFull = function (temp) {
  return formatDateChileDate(temp) + ' (' + formatDateChileElapsed(temp) + ')'
}

export default {
  name: 'carroComponent',
  // props: ['boxes', 'estudio'],
  data () {
    return {
      VUE_APP_API_SERVER: process.env,
      data: [],
      caso: null,
      misDatos: [],
      userData: {},
      loading: false,
      showBackToLogin: false,
      abono: 0,
      webpayData: {},
      mostrarTyc: false,
      webpayDataUrl: null,
      webpayDataToken: null,
      transactionError: false,
      transactionErrorText: '',
      isTesting: process.env.VUE_APP_ENV
      // deSantiago: this.$store.state && this.$store.state.studio !== 'BYCYOGA_Vitacura'
    }
  },
  mounted () {
    this.loadAuthContent()
  },
  computed: {
    deSantiago () {
      let out = false
      if (this.$store.state && this.$store.state.studio) {
        if (['BYCYOGA_Valdivia', 'BYCYOGA_Puertomontt', 'BYCYOGA_Puertovaras'].indexOf(this.$store.state.studio) === -1) out = true
      }
      return out
      // return this.$store.state && this.$store.state.studio !== 'BYCYOGA_Vitacura'
    },
    total () {
      const self = this
      const { data, abono, userData } = self
      let saldoExistente = 0
      if (userData.saldoMonto) saldoExistente = parseInt(userData.saldoMonto || 0)
      let subtotal = 0
      let clases = 0
      if (data.length) {
        clases = data.length
        data.forEach(d => {
          subtotal += parseInt(d.precioSpot || 0)
        })
      }
      let total = parseInt(subtotal || 0) - parseInt(saldoExistente || 0)
      if (total <= 0) total = 0
      total += parseInt(abono || 0)
      return { clases, subtotal, abono: parseInt(abono || 0), total, saldoExistente }
    }
  },
  methods: {
    // deSantiago () {
    //   return true
    // },
    setMembresia (caso) {
      const text = `¡Atención! Luego de comprar su programa First Timer debe agendar las clases en días corridos, eligiendo el horario y el box de la sala. Este programa tiene una duracion de 7 días.\n
Solo pueden optar a este beneficio alumnos nuevos que no hayan practicado antes en ningún estudio.\n
Si usted ya ha practicado antes en nuestros estudios y compra este programa First Timer no se hará efectivo el beneficio, solo se abonarán $35.000.`

      const textEstudiante = `¡Atención!\n
   Acércate al estudio para Activar tu programa.
   - Solicitaremos tu cédula de identidad o certificado de alumno regular vigente.
   - El uso de este programa se suscribe únicamente al horario 13:30 pm y 16:15 pm de lunes a viernes en BYC YOGA VITACURA.
   `

      const textGeneral = `¡Atención!

      Acércate al estudio para Activar tu programa.
   - El uso de este programa se suscribe únicamente al horario 13:30 pm y 16:15 pm de lunes a viernes en BYC YOGA VITACURA.
   `

      if (caso + '' === '630') this.abono = 115000
      if (caso + '' === '830') this.abono = 150000
      if (caso + '' === '1230') this.abono = 210000

      if (caso + '' === '707') this.abono = 35000
      if (caso + '' === '707') window.alert(text)

      if (caso + '' === '30301') this.abono = 320000
      if (caso + '' === '60602') this.abono = 339000 // caso sur

      // nuevos planes vitacura (2)
      if (caso + '' === '8006001') this.abono = 75000
      if (caso + '' === '8006001') window.alert(textEstudiante)
      if (caso + '' === '8006002') this.abono = 162000
      if (caso + '' === '8006002') window.alert(textGeneral)

      this.caso = caso
    },
    abrirTYCMembresia () {
      window.open('/tyc.html', '_blank')
      // window.open('/tycMembresia.html', '_blank')
    },
    abrirTYC () {
      window.open('/tyc.html', '_blank')
    },
    reload () {
      window.location.reload()
    },
    formatDate (clase) {
      let temp
      try {
        temp = clase.computed.appsBycyogaClaseInstance.date
      } catch (e) {}
      return formatDateChileFull(temp)
    },
    getBoxName (clase) {
      return clase.computed.appsBycyogaBox.text
    },
    async cancelClass (cartElementId) {
      if (window.confirm('Seguro quieres sacar esta clase del carro?')) {
        const self = this
        // const { data, abono } = self
        self.loading = true
        const token = localStorage.getItem('bycyoga-jwt')
        let headers = {}
        if (token && token.length) headers = { Authorization: `Bearer ${token}` }
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'remove-from-cart', payload: { cartElementId } }, { headers })
        if (response && response.data) self.loadAuthContent()
        self.loading = false
      }
    },
    async checkoutDo () {
      const self = this
      const { data, caso, abono } = self

      self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'checkout', payload: { data, abono, caso } }, { headers })
        if (response && response.data) self.webpayData = response.data
        if (response && response.data) self.webpayDataToken = response.data.token
        if (response && response.data) self.webpayDataUrl = response.data.url
        if (self.webpayData && self.webpayData.token === null) {
          // caso en que no hay tbk
          self.loadAuthContent()
          // const textoAdicional = '\n\nRecibirás un correo con los detalles'
          const textoAdicional = ''
          if (self.webpayData.contadorDeErrores > 0) {
            window.alert('!ATENCIÓN! No todas las clases pudieron ser compradas' + textoAdicional)
          } else {
            window.alert('Compra realizada!' + textoAdicional)
          }
          // ahora redirect a mis clases
          this.$router.push({ name: 'MisClases', params: { } })
        } else {
          // self.go()
        }
      } catch (e) {
        self.transactionError = true
        self.transactionErrorText = e.message
      }
      self.loading = false
    },
    async preCheckout () {
      this.mostrarTyc = true
      this.checkoutDo()
    },
    async preCheckout2 () {
      this.mostrarTyc = true
    },
    async checkout () {
      this.checkoutDo()
    },
    async go () {
      // asignar token
      // document.getElementById('token').value = this.webpayData.token // no se puede remover esto, se cae
      // this.webpayDataToken = this.webpayData.token // no se puede remover esto, se cae
      const self = this

      setTimeout(function () {
        const tagForm = self.$refs.myForm
        // asignar action
        tagForm.action = self.webpayData.url
        tagForm.submit() // go!
      }, 500)
    },
    async loadAuthContent () {
      this.loading = true
      const self = this
      self.$store.commit('setError', false)
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'mi-carro' }, { headers })
        self.loading = false
        if (response.data) {
          const { cartData, userData } = response.data
          if (cartData && cartData.data) self.data = cartData.data
          if (userData && userData.data) self.userData = userData.data[0]
        }
      } catch (e) {
        self.$store.commit('setError', true)
      }
    }
  }
}
</script>
