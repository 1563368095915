<template>
  <!-- <h3 class="center">Buscar nueva clase</h3> -->
  <template v-if="$store.state.studio">
  <div class="row">
    <div class="col-xs-12 col-md-4 offset-md-4">
      <div class="center">
        <router-link to="/carro"><div class="btn btn-success" id="membresia"><i class="fas fa-award"></i>&nbsp;&nbsp;obtén tu programa acá&nbsp;&nbsp;<i class="fas fa-award"></i></div></router-link>
        <br />
        <br />
      </div>
      <input
        type="text"
        class="form-control dark-or-not"
        styleOld="color: #666 !important;font-weight: bold; text-align: center; background: white !important; font-size: 18px; text-transform: uppercase;"
        style="font-weight: bold; text-align: center; background: rgb(0, 40, 145) !important; border:  rgb(0, 40, 145); color:  white !important; font-size: 18px; text-transform: uppercase;"
        placeholderOld="selecciona tu clase aquí"
        placeholder="selecciona la fecha"
        placeholderNew="selecciona la fecha"
        ref="picker"
      />
    </div>
  </div>
  </template>
  <!-- <div class="row center">
    <div class="col-12 col-xl-8 offset-xl-2">
      <Boxes :boxes="possibleBoxes" :estudio="'BYCYOGA_Valdivia'" @boxSeleccionado="handleSeleccionado" />
      <br />
      <Boxes :boxes="possibleBoxes" :estudio="'BYCYOGA_Nunoa'" @boxSeleccionado="handleSeleccionado" />
      <br />
      <Boxes :boxes="possibleBoxes" :estudio="'BYCYOGA_Quinchamali'" @boxSeleccionado="handleSeleccionado" />
    </div>
  </div> -->
  <div v-if="loading" class="center">
    <br><i class="fas fa-circle-notch fa-spin"></i> buscando clases...
  </div>
  <template v-else>
    <template v-if="dataClasesPosibles.length > 0">
      <div class="row center" v-for="clase of dataClasesPosibles" v-bind:key="clase._id">
        <div class="col-12 col-xl-8 offset-xl-2" v-if="appsBycyogaClaseInstanceId === clase._id || appsBycyogaClaseInstanceId === null">
          <br>
          {{clase.diasRestantesElapsed}} - {{clase.computed.appsBycyogaClase.text}} <span class="badge badge-light"></span>&nbsp;
          <template v-if="appsBycyogaClaseInstanceId !== null"></template>
          <template v-else>
            <template v-if="clase.capacidadDisponible === 0">
              <button disabled class="btn btn-danger btn-sm">sin cupos presencial</button>
              <!-- &nbsp;<button :disabled="loadingCart" class="btn btn-primary btn-sm" @click.prevent="addToCartZoom(clase._id)">zoom <span v-if="loadingCart" ><i class="fas fa-circle-notch fa-spin"></i></span></button> -->
              <!-- @todo que los cupos de zoom se cuenten -->
            </template>
            <template v-else>
              <button :disabled="loadingCart" class="btn btn-primary btn-sm" @click.prevent="seleccionarClase(clase._id)">seleccionar clase {{calcularCapacidad(clase.capacidadDisponible)}} <span v-if="loadingCart" ><i class="fas fa-circle-notch fa-spin"></i></span></button>
              <!-- &nbsp;<button :disabled="loadingCart" class="btn btn-primary btn-sm" @click.prevent="addToCartZoom(clase._id)">zoom <span v-if="loadingCart" ><i class="fas fa-circle-notch fa-spin"></i></span></button> -->
            </template>
          </template>
          <template v-if="appsBycyogaClaseInstanceId !== null">
            <div class="row" style="margin-left: 0px; margin-right: 0px;">
              <div class="col" v-for="tarifa of clase.tarifasValidas" v-bind:key="tarifa.text">
                <div class="btn btn-primary btn-sm" @click.prevent="seleccionarTarifa(tarifa._id, tarifa.montoConDescuento)"><span style="">Tarifa {{tarifa.nombre}}</span><br><span>${{tarifa.montoConDescuento}}</span></div>
              </div>
            </div>
            <br>
          </template>
          <template v-if="appsBycyogaTarifaId !== null">
            <span v-if="loadingBoxes" >verificando disponibilidad de boxes <i class="fas fa-circle-notch fa-spin"></i></span>
            <template v-if="estudio === 'BYCYOGA_Tobalaba'">
              <h3>Estudio Tobalaba</h3>
            </template>
            <template v-if="estudio === 'BYCYOGA_Puertomontt'">
              <h3>Estudio Puerto Montt</h3>
            </template>
            <template v-if="estudio === 'BYCYOGA_Puertovaras'">
              <h3>Estudio Puerto Varas</h3>
            </template>
            <template v-if="estudio === 'BYCYOGA_Vitacura'">
              <h3>Estudio Vitacura</h3>
            </template>
            <template v-if="estudio === 'BYCYOGA_Quinchamali'">
              <h3>Estudio Quinchamalí</h3>
            </template>
            <template v-if="estudio === 'BYCYOGA_Nunoa'">
              <h3>Estudio Ñuñoa</h3>
            </template>
            <template v-if="estudio === 'BYCYOGA_Valdivia'">
              <h3>Estudio Valdivia</h3>
            </template>
            <div class="row">
              <div class="col" style="margin-bottom: 5px;">
                <button :disabled="loadingCart" v-if="boxNumber" class="btn btn-primary" @click.prevent="addToCart(boxNumber)">
                  <i class="fa fa-cart-arrow-down" aria-hidden="true"></i> agregar al carro <span v-if="loadingCart" ><i class="fas fa-circle-notch fa-spin"></i></span>
                </button>
              </div>
            </div>

            <Boxes :boxes="possibleBoxes" :estudio="estudio" @boxSeleccionado="handleSeleccionado" />

            <template v-if="false">
              <br>inicio debug:
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Tobalaba" @boxSeleccionado="handleSeleccionado" />
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Quinchamali" @boxSeleccionado="handleSeleccionado" />
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Vitacura" @boxSeleccionado="handleSeleccionado" />
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Nunoa" @boxSeleccionado="handleSeleccionado" />
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Puertomontt" @boxSeleccionado="handleSeleccionado" />
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Puertovaras" @boxSeleccionado="handleSeleccionado" />
              <br><Boxes :boxes="possibleBoxes" estudio="BYCYOGA_Valdivia" @boxSeleccionado="handleSeleccionado" />
            </template>
            <br>
            <button :disabled="loadingCart" v-if="boxNumber" class="btn btn-primary" @click.prevent="addToCart(boxNumber)">
              <i class="fa fa-cart-arrow-down" aria-hidden="true"></i> agregar al carro <span v-if="loadingCart" ><i class="fas fa-circle-notch fa-spin"></i></span>
            </button>
          </template>
        </div>
      </div>
    </template>
    <div v-else class="center">
      <br>No hay datos para mostrar.<br>Prueba seleccionando otra fecha.
    </div>
  </template>

  <!-- <div class="center">
    <br>
    ¿Estás listo para pagar? <router-link to="/carro">ve los detalles en tu carro de compras</router-link>
  </div> -->

</template>

<script>
import axios from 'axios'
import flatpickr from 'flatpickr'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import Boxes from '@/components/Boxes.vue'
// import { English } from "flatpickr/dist/l10n/en.js"
const moment = require('moment-timezone')

// de utils
/**
 * formatear fecha en elapsed time o remaining
 */
const formatDateChileElapsed = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').fromNow()
}

export default {
  data () {
    return {
      flatpickr: {},
      isValid: false,
      isInvalid: false,
      message: 'nada todavía',
      wasValidated: false,
      fecha: '',
      dataClasesPosibles: [],
      dataMisClases: [],
      loading: false,
      loadingMisClases: false,
      loadingCart: false,
      appsBycyogaClaseInstanceId: null,
      appsBycyogaTarifaId: null,
      boxNumber: null,
      precioSpot: null,
      possibleBoxes: [],
      estudio: ''
    }
  },
  components: { Boxes },
  mounted () {
    flatpickr.l10ns.default.firstDayOfWeek = 1 // Monday
    const self = this
    const theLocale = Spanish
    const dateFormat = 'j F, Y'
    const options = {
      // inline: true,
      position: 'auto center',
      dateFormat,
      disable: [
        function (date) {
          // const ayer = Date.now() - 1000 * 60 * 60 * 24
          // const sesentaDiasMas = Date.now() + (1000 * 60 * 60 * 24) * 60
          // const hoyOFuturo = date.getTime() < ayer
          // const sobreSesentaDias = date.getTime() > sesentaDiasMas
          // return (hoyOFuturo || sobreSesentaDias)
          return false
        }
      ],
      onChange: function (selectedDates, dateStr, instance) {
        // console.log('>>> emit onChange1', selectedDates, dateStr, instance)
        // console.log('>>> emit 2 (millis)', selectedDates[0].valueOf())
        self.fecha = selectedDates[0].valueOf()
        self.appsBycyogaClaseInstanceId = null
        self.appsBycyogaTarifaId = null
        self.precioSpot = null
        self.loadClasesDisponibles()
      }
    }

    if (theLocale) options.locale = theLocale
    try {
      self.flatpickr = flatpickr(this.$refs.picker, options)
      self.flatpickr.open() // a prueba
    } catch (e) {}
    setTimeout(function () {
      // self.flatpickr
    }, 100)
  },
  methods: {
    calcularCapacidad (cantidad = 0) {
      const self = this
      // console.log('>>>> estudio', self.estudio === 'BYCYOGA_Vitacura', self.estudio)
      let delta = 0

      // si el estudio es vitacura le resto 12 a los cupos
      if (self.estudio === 'BYCYOGA_Vitacura') delta = (12 + 4)
      if (self.estudio === 'BYCYOGA_Tobalaba') delta = (12 + 5)
      if (self.estudio === 'BYCYOGA_Quinchamali') delta = 0
      if (self.estudio === 'BYCYOGA_Puertomontt') delta = 14
      if (self.estudio === 'BYCYOGA_Puertovaras') delta = 15
      if (self.estudio === 'BYCYOGA_Valdivia') delta = 11

      // ({{calcularCapacidad(clase.capacidadDisponible)}} cupos)
      if (self.estudio === 'BYCYOGA_Vitacura') {
        return ''
      } else {
        return `(${cantidad - delta} cupos) `
      }
    },
    handleSeleccionado (some) {
      this.boxNumber = null
      this.boxNumber = some
    },
    algo (clase) {
      return clase.computed.appsBycyogaClaseInstance.computed.appsBycyogaClase.text + ' (' + formatDateChileElapsed(clase.computed.appsBycyogaClaseInstance.date) + ')'
    },
    getBoxName (clase) {
      return clase.computed.appsBycyogaBox.text
    },
    async cancelClass (appsBycyogaClaseBoxId) {
      const message = `Seguro quieres cancelar esta clase?

Una vez cancelada se abonará el valor correspondiente a tu saldo`
      if (window.confirm(message)) {
        const self = this
        self.loadingMisClases = true
        const token = localStorage.getItem('bycyoga-jwt')
        let headers = {}
        if (token && token.length) headers = { Authorization: `Bearer ${token}` }
        const cancelResult = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'cancel-class', payload: { appsBycyogaClaseBoxId } }, { headers })
        if (cancelResult.result) {
          if (cancelResult.result.result === true) {
            window.alert('Tu clase fue anulada y abonada a tu saldo')
          } else {
            window.alert('Hubo un problema al anular tu clase\n\n' + cancelResult.result.errMsg)
          }
        }
      }
    },
    seleccionarClase (appsBycyogaClaseInstanceId) {
      this.appsBycyogaClaseInstanceId = appsBycyogaClaseInstanceId
    },
    async seleccionarTarifa (appsBycyogaTarifaId, precioSpot) {
      this.appsBycyogaTarifaId = appsBycyogaTarifaId
      this.precioSpot = precioSpot
      this.possibleBoxes = []
      this.boxNumber = null
      this.loadingBoxes = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      // ahora hacer load de los boxes disponibles
      const result = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'get-boxes', payload: { appsBycyogaTarifaId, appsBycyogaClaseInstanceId: this.appsBycyogaClaseInstanceId } }, { headers })
      this.possibleBoxes = result.data
      // ver resultado y luego desplegar
      this.loadingBoxes = false
    },
    loadClasesDisponibles () {
      const self = this
      self.$store.commit('setError', false)
      self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      axios
        .post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'buscar-clases', payload: { fecha: self.fecha } }, { headers })
        .then(response => {
          self.loading = false
          self.estudio = response.data.estudio
          if (response && response.data && response.data.data) self.dataClasesPosibles = response.data.data
          self.loading = false
        })
        .catch(e => {
          self.loading = false
          self.showBackToLogin = true
          self.$store.commit('setError', true)
        })
    },
    async addToCartZoom (appsBycyogaClaseInstanceId) {
      const self = this
      self.loadingCart = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'add-to-cart-zoom', payload: { appsBycyogaClaseInstanceId } }, { headers })
      if (response && response.data) {
        this.$router.push({ name: 'Carro', params: { } })
      }
      // self.loadingCart = false
      self.loadingCart = false
    },
    async addToCart (boxNumber) {
      const self = this
      // self.loading = true
      self.loadingCart = true
      const { appsBycyogaClaseInstanceId, appsBycyogaTarifaId, precioSpot } = self
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'add-to-cart', payload: { appsBycyogaClaseInstanceId, appsBycyogaTarifaId, boxNumber, precioSpot } }, { headers })
      self.appsBycyogaClaseInstanceId = null
      self.appsBycyogaTarifaId = null
      self.boxNumber = null
      self.loading = false
      self.loadingCart = false

      // redirect al carro
      this.$router.push({ name: 'Carro', params: { } })
    }
  },
  watch: {}
}
</script>

<style type="text/css">
::placeholder {
  color: white !important;
}
</style>
