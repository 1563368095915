<template>
  <div class="container">
    <div class="row">
      <div class="offset-lg-3 col-lg-6">
        <div class="card">
          <div class="card-body">
            <form>
              <h3>Acceso usuarios</h3>
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" placeholder="email">
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="form-label">Clave</label>
                <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="clave">
              </div>
              <br>
              <template v-if="showWarning">
                <!-- <div class="container"> -->
                  <!-- <div class="row"> -->
                    <!-- <div class="col"> -->
                      <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>¡Lamentablemente hubo un error!</strong> <div v-html="showWarningText"></div>
                      </div>
                    <!-- </div> -->
                  <!-- </div> -->
                <!-- </div> -->
              </template>
              <button :disabled="loading" class="btn btn-primary" @click.prevent="userLogin">
                entrar <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
              </button>
              <br>
              <br>
              <div class="center">
                <template v-if="true">
                &nbsp;
                  <router-link to="/register">
                    <div class="btn btn-success btn-sm">Alumnos nuevos registrarse acá
                      <!-- <br><span style="font-size: 11px;"><i class="fas fa-award"></i> $21.000</span> -->
                    </div>
                  </router-link>
                </template>
                <!-- Si no eres usuario <router-link to="/register">regístrate acá</router-link> -->
              </div>
              <!-- <br> -->
              <br>
              <div class="center">¿Olvidaste tu clave? <a href="#" @click.prevent="solicitaClave">solicita una nueva acá</a></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
const wait = timeout => (new Promise(resolve => setTimeout(resolve, timeout)))

export default {
  name: 'LoginRegister',
  data () {
    return {
      data: [],
      loading: false,
      email: '',
      password: '',
      nombre: '',
      apellido: '',
      rut: '',
      telefono: '',
      showEstudios: false,
      showWarning: false,
      showWarningText: ''
    }
  },
  mounted () {},
  methods: {
    async solicitaClave () {
      const self = this
      self.loading = true
      const { email } = self
      try {
        const result = await self.$store.dispatch('doReset', { email: email ? email.toString().toLowerCase().trim() : '' })
        await wait(1000)
        if (result && result.data && result.data.result === true) {
          window.alert('Por favor revisa tu correo (si no te llega en 5 minutos revisa la carpeta no deseados por favor)')
        } else {
          self.showWarning = true
          self.showWarningText = '<br>Hubo un problema al intentar solicitar una nueva clave, recuerda ingresar tu email, todo en minúsculas.'
        }
        self.loading = false
      } catch (e) {
        self.loading = false
      }
    },
    async userRegister () {
      const self = this
      const { email, nombre, apellido, rut, telefono, password } = self
      self.loading = true
      try {
        const loginResult = await self.$store.dispatch('doRegister', { email: email ? email.toString().toLowerCase().trim() : '', nombre, apellido, rut, telefono, password })
        const { result, errMsg } = loginResult.data
        if (result === true) {
          self.userLogin()
        } else {
          if (result === false) {
            self.showWarning = true
            self.showWarningText = `<br>Hubo un error al registrar al usuario<br><span style="font-weight: bold">REF:</span> ${errMsg}`
          } else {
            console.log('>>> caso inesperado', result)
          }
          self.loading = false
        }
      } catch (e) {
        self.loading = false
      }
      self.loading = false
    },
    async userLogin () {
      const self = this
      const { email, password } = self
      self.loading = true
      self.showWarning = false

      try {
        const loginResult = await axios.post(process.env.VUE_APP_API_SERVER + '/authentication', { strategy: 'local', email: email ? email.toString().toLowerCase().trim() : '', password })
        if (loginResult && loginResult.data && loginResult.data.accessToken) {
          const jwt = loginResult.data.accessToken
          self.$store.dispatch('storeLoginData', jwt)
          self.$router.push({ name: 'Estudios', params: { } })
          return { result: true, jwt }
        } else {
          self.showWarning = true
          self.showWarningText = '<br>Por favor revisa tu email y tu clave y vuelve a intentar'
          return { result: false }
        }
      } catch (e) {
        self.showWarning = true
        self.showWarningText = `<br>Por favor revisa tu email y tu clave y vuelve a intentar<br><span style="font-weight: bold">REF:</span> ${e.message}`
        return { result: false, reason: e }
      } finally {
        self.loading = false
      }
    }
  }
}
</script>
