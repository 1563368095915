// import path from 'path'
import { createStore } from 'vuex'
import {
  // verifyLogin,
  doLogin,
  doRegister,
  doSetStudio,
  doReset,
  doVerify,
  find,
  get,
  create,
  remove,
  update,
  proxy,
  storeLoginData
} from './api.js'

/* eslint-disable no-useless-constructor */
export default createStore({
  state: {
    jwt: '',
    jwtExp: Date.now() - 1,
    code: 'the code goes here',
    enableEspaciosVitacura: true,
    count: 0,
    references: [],
    temp: 'the id',
    messageQueue: [],
    loading: false,
    theme: 'theme',
    payload: {},
    route: {},
    error: false,
    studio: '',
    alumnoData: {}
  },

  mutations: {
    setJwt (state, newValue) {
      state.jwt = newValue
    },
    setJwtExp (state, newValue) {
      state.jwtExp = newValue
    },
    setCode (state, newValue) {
      state.code = newValue
    },
    increment (state) {
      state.count++
    },
    addReference (state, newValue) {
      state.references.push(newValue)
    },
    setTemp (state, newValue) {
      state.temp = newValue
    },
    setPayload (state, newValue) {
      state.payload = newValue
    },
    setRoute (state, newValue) {
      state.route = newValue
    },
    setLoading (state, newValue) {
      state.loading = newValue
    },
    setError (state, newValue) {
      state.error = newValue
    },
    setStudio (state, newValue) {
      state.studio = newValue
    },
    setAlumnoData (state, newValue) {
      state.alumnoData = newValue
    }

  },

  actions: {
    // verifyLogin,
    doLogin,
    doRegister,
    doSetStudio,
    doReset,
    doVerify,
    find,
    get,
    create,
    remove,
    update,
    proxy,
    storeLoginData
  },

  modules: {},

  getters: {
    getJwt: state => state.jwt,
    getJwtExp: state => state.jwtExp,
    getCode: state => state.code,
    count: state => state.count,
    getReferences: state => state.references,
    getTemp: state => state.temp,
    getPayload: state => state.payload,
    getRoute: state => state.route,
    getLoading: state => state.loading,
    getError: state => state.error,
    getStudio: state => state.studio,
    getAlumnoData: state => state.alumnoData
  }
})
