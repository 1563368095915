import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
// import Login from '@/components/Login.vue'
import LoginRegister from '@/components/LoginRegister.vue'
import Register from '@/components/Register.vue'
import Estudios from '@/components/Estudios.vue'
import EstudiosBeta from '@/components/EstudiosBeta.vue'
import MisClases from '@/components/MisClases.vue'
import BuscarClases from '@/components/BuscarClases.vue'
import Carro from '@/components/Carro.vue'
import Help from '@/components/Help.vue'
import MisDatos from '@/components/MisDatos.vue'
import Becas from '@/components/Becas.vue'
import FinTransaccion from '@/components/FinTransaccion.vue'
import PasswordReset from '@/components/PasswordReset.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: LoginRegister
    // component: Home
  }, {
    path: '/help',
    name: 'Help',
    component: Help
  }, {
    path: '/login',
    name: 'Login',
    component: LoginRegister
  }, {
    path: '/register',
    name: 'Register',
    component: Register
  }, {
    path: '/estudios',
    name: 'Estudios',
    component: Estudios
  }, {
    path: '/estudios-beta',
    name: 'EstudiosBeta',
    component: EstudiosBeta
  }, {
    path: '/mis-datos',
    name: 'MisDatos',
    component: MisDatos
  }, {
    path: '/becas',
    name: 'Becas',
    component: Becas
  }, {
    path: '/mis-clases',
    name: 'MisClases',
    component: MisClases
  }, {
    path: '/buscar-clases',
    name: 'BuscarClases',
    component: BuscarClases
  }, {
    path: '/carro',
    name: 'Carro',
    component: Carro
  }, {
    path: '/fin-transaccion',
    name: 'FinTransaccion',
    component: FinTransaccion
  }, {
    path: '/fin-transaccion/:params',
    name: 'FinTransaccion2',
    component: FinTransaccion
  }, {
    path: '/password-reset/:token',
    name: 'PasswordReset',
    component: PasswordReset
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
