<template>
  <!-- <div>fin transaccion {{ttt()}}</div> -->
  <!-- <div>fin transaccion {{$route.query}}</div> -->
  <!-- <div>Resultado: {{query.resultado}}</div> -->
  <!-- <div>Mensaje: {{query.message}}</div> -->

  <!-- fail -->
  <div class="container center" v-if="query.resultado === 'fail1'">
    <div class="row" style="">
      <div class="col"><h1 class="success">Problemas con el pago.</h1><br>La transacción no fue encontrada, por favor intenta nuevamente.
        <!-- <br><br>Las razones más comunes por las que fallan las transacciones son:<ul> -->
        <!-- <li>Error en el ingreso de los datos de su tarjeta (fecha y/o código de seguridad por ejemplo)</li> -->
        <!-- <li>La tarjeta no cuenta con saldo suficiente</li> -->
        <!-- <li>La tarjeta aún no habilitada en el sistema financiero</li> -->
      <!-- </ul> -->
      </div>
    </div>
  </div>
  <div class="container center" v-if="query.resultado === 'fail2'">
    <div class="row" style="">
      <div class="col"><h1 class="success">Problemas con el pago.</h1><br>Los datos no son válidos, por favor intenta nuevamnete.<br><br>Las razones más comunes por las que fallan las transacciones son:<ul>
        <li>Error en el ingreso de los datos de su tarjeta (fecha y/o código de seguridad por ejemplo)</li>
        <li>La tarjeta no cuenta con saldo suficiente</li>
        <li>La tarjeta aún no habilitada en el sistema financiero</li>
      </ul></div>
    </div>
  </div>

  <!-- rechazo -->
  <div class="container center" v-if="query.resultado === 'rechazo'">
    <div class="row" style="">
      <div class="col"><h1 class="success">Pago rechazado.</h1><br>La transacción ha sido rechazada y no hicimos ningún cobro en tu tarjeta.</div>
    </div>
  </div>

  <!-- anulacion -->
  <div class="container center" v-if="query.resultado === 'anulacion'">
    <div class="row" style="">
      <div class="col"><h1 class="success">Pago anulado.</h1><br>La transacción ha sido cancelada y no hicimos ningún cobro en tu tarjeta.</div>
    </div>
  </div>

  <!-- success -->
  <div class="container center" v-if="transactionResult && query.resultado === 'success'">
    <div class="row" style="">
      <div class="col"><h1 class="success">Pago exitoso.</h1><br>A continuación los detalles de la transacción:<br><br></div>
    </div>
    <div class="row" style="">
      <div class="col"><span class="bold">Monto:</span> ${{transactionResult.amount}}</div>
    </div>
    <div class="row">
      <div class="col"><span class="bold">Estado:</span> {{transactionResult.status}}</div>
    </div>
    <div class="row" v-if="transactionResult.card_detail">
      <div class="col"><span class="bold">Tarjeta terminada en:</span> {{transactionResult.card_detail.card_number}}</div>
    </div>
    <div class="row">
      <div class="col"><span class="bold">Fecha:</span> {{transactionResult.transaction_date}}</div>
      <!-- <div class="col"><span class="bold">Fecha:</span> {{new Date(transactionResult.transaction_date)}}</div> -->
    </div>
    <div class="row">
      <div class="col"><span class="bold">Código de autorización:</span> {{transactionResult.authorization_code}}</div>
    </div>
  </div>
  <!-- <div v-if="query.buyOrder">Buy order: {{query.buyOrder}}</div> -->
  <div v-if="query.data">Data: {{query.data}}</div>
</template>

<script>
const axios = require('axios')
const moment = require('moment-timezone')
// de utils
/**
 * formatear fecha en elapsed time o remaining
 */
const formatDateChileElapsed = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').fromNow()
}

export default {
  name: 'FinTransaccion',
  props: {
    msg: String
  },
  data () {
    return {
      VUE_APP_API_SERVER: process.env,
      data: [],
      loading: false,
      showBackToLogin: false,
      query: {},
      transactionResult: {}
    }
  },
  mounted () {
    // this.loadAuthContent()
    console.log('>>> here', this.$route.query)
    this.query = this.$route.query
    if (this.query.resultado === 'success') this.loadBuyOrderData()
  },
  computed: {},
  methods: {
    ttt () {
      // const { currentRoute } = this.$router
      return this.$route.query
    },
    algo (clase) {
      return clase.computed.appsBycyogaClaseInstance.computed.appsBycyogaClase.text + ' (' + formatDateChileElapsed(clase.computed.appsBycyogaClaseInstance.date) + ')'
    },
    getBoxName (clase) {
      return clase.computed.appsBycyogaBox.text
    },
    cancelClass (claseId) {
      console.log('>>> about to cancel class with id', claseId)
    },
    async loadBuyOrderData () {
      const self = this
      self.loading = true
      const { buyOrder } = self.query
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy-open', { action: 'transaction-data', payload: { buyOrder } }, { headers })
      try {
        const { transactionResult } = response.data[0]
        console.log('>>> got it!', transactionResult)
        this.transactionResult = transactionResult
      } catch (e) {}
      self.loading = false
    }
  }
}
</script>
<style>
.bold {
  font-weight: 600;
 }
</style>
