<template>
  <div class="container">
    <div class="row">
      <div class="offset-lg-3 col-lg-6">
        <div class="card">
          <div class="card-body">
            <form>
              <h3>Registro de usuarios</h3>
              <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Email</label>
                <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" placeholder="email">
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1" class="form-label">Clave</label>
                <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="clave">
              </div>

              <div class="form-group">
                <label for="nombre" class="form-label">Nombre</label>
                <input v-model="nombre" type="text" class="form-control" id="nombre" placeholder="nombre">
              </div>

              <div class="form-group">
                <label for="apellido" class="form-label">Apellido</label>
                <input v-model="apellido" type="text" class="form-control" id="apellido" placeholder="apellido">
              </div>

              <div class="form-group">
                <label for="rut" class="form-label">RUT</label>
                <input v-model="rut" type="text" class="form-control" id="rut" placeholder="RUT">
              </div>

              <div class="form-group">
                <label for="telefono" class="form-label">Teléfono</label>
                <input v-model="telefono" type="text" class="form-control" id="telefono" placeholder="teléfono">
              </div>
              <br>
              <div class="alert alert-danger alert-dismissible fade show" role="alert">
                <h4 class="alert-heading">¡Atención !</h4>
                <a href="/tyc.html" target="_blank">Al registrarte estás aceptando los términos y condiones (click acá para verlos)</a>
                <!-- <hr> -->
                <!-- <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click.prevent="hideWarning"></button> -->
              </div>
              <template v-if="showWarning">
                <!-- <div class="container"> -->
                  <!-- <div class="row"> -->
                    <!-- <div class="col"> -->
                      <div class="alert alert-warning alert-dismissible fade show" role="alert">
                        <strong>¡Lamentablemente hubo un error!</strong> <div v-html="showWarningText"></div>
                      </div>
                    <!-- </div> -->
                  <!-- </div> -->
                <!-- </div> -->
              </template>
              <!-- <br> -->
              <button :disabled="loading" class="btn btn-primary" @click.prevent="userRegister">
                aceptar TYC y registrarme <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
              </button>
              <br>
              <br>
              <router-link to="/login">¿Ya tienes una cuenta? Entra acá</router-link>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const axios = require('axios')
const wait = timeout => (new Promise(resolve => setTimeout(resolve, timeout)))

export default {
  name: 'RegisterComponent',
  data () {
    return {
      data: [],
      loading: false,
      email: '',
      password: '',
      nombre: '',
      apellido: '',
      rut: '',
      telefono: '',
      showEstudios: false,
      createUser: false,
      showWarning: false,
      showWarningText: ''
    }
  },
  mounted () {},
  methods: {
    async solicitaClave () {
      const self = this
      self.loading = true
      const { email } = self
      try {
        const result = await self.$store.dispatch('doReset', { email: email ? email.toString().toLowerCase().trim() : '' })
        console.log('>>> resultado', result)
        await wait(1000)
        // await wait(1000 * 3)
        if (result && result.result === true) {
          window.alert('Por favor revisa tu correo (si no te llega en 5 minutos revisa la carpeta no deseados por favor)')
        } else {
          self.showWarning = true
          self.showWarningText = '<br>Hubo un problema al intentar solicitar una nueva clave, recuerda ingresar tu email, todo en minúsculas.'
        }
        self.loading = false
      } catch (e) {
        self.loading = false
      }
      // self.loading = false
    },
    async userRegister () {
      const self = this
      const { email, nombre, apellido, rut, telefono, password } = self
      self.loading = true
      try {
        const loginResult = await self.$store.dispatch('doRegister', { email: email ? email.toString().toLowerCase().trim() : '', nombre, apellido, rut, telefono, password })
        console.log('>>> resultado de crear', loginResult)
        const { result, errMsg } = loginResult.data
        if (result === true) {
          self.userLogin()
        } else {
          if (result === false) {
            // window.alert('Hubo un error al registrar al usuario\n\n' + errMsg)
            self.showWarning = true
            self.showWarningText = `<br>Hubo un error al registrar al usuario<br><span style="font-weight: bold">REF:</span> ${errMsg}`
          } else {
            console.log('>>> caso inesperado', result)
          }
          self.loading = false
        }
      } catch (e) {
        self.loading = false
      }
      self.loading = false
    },
    async userLogin () {
      const self = this
      const { email, password } = self
      self.loading = true
      self.showWarning = false

      try {
        const loginResult = await axios.post(process.env.VUE_APP_API_SERVER + '/authentication', { strategy: 'local', email: email ? email.toString().toLowerCase().trim() : '', password })
        if (loginResult && loginResult.data && loginResult.data.accessToken) {
          const jwt = loginResult.data.accessToken
          self.$store.dispatch('storeLoginData', jwt)
          self.$router.push({ name: 'Estudios', params: { } })
          // self.$router.push({ name: 'MisClases', params: { } })
          return { result: true, jwt }
        } else {
          self.showWarning = true
          self.showWarningText = '<br>Por favor revisa tu email y tu clave y vuelve a intentar'
          return { result: false }
        }
      } catch (e) {
        self.showWarning = true
        // let message = ''
        // if (e.message === 'Request failed with status code 401') message = 'some'
        self.showWarningText = `<br>Por favor revisa tu email y tu clave y vuelve a intentar<br><span style="font-weight: bold">REF:</span> ${e.message}`
        return { result: false, reason: e }
      } finally {
        self.loading = false
      }
    }
  }
}
</script>
