<template>
  <div class="container">
    <div class="row">
      <div class="offset-lg-3 col-lg-6">
        <div class="card">
          <div class="card-body">
            <form>
              <h3>¿No estás seguro?</h3>
              <div>Revisa si tu cuenta ya está creada o no</div>
              <div class="form-group">
                <!-- <label for="exampleInputEmail1" class="form-label">Email</label> -->
                <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" placeholder="ingresa tu correo acá">
              </div>
              <br>
              <button :disabled="loading" class="btn btn-primary" @click.prevent="verifyAccount">
                click acá para revisar si mi cuenta existe o no <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
              </button>
              <br>
              <router-link to="/login">Acceso usuarios</router-link>
              <br>
              <router-link to="/register">Registro nuevos usuarios</router-link>
              <br>
<!--               <div class=""><router-link to="/login">ingreso</router-link></div>
 -->
              <br>
              <br>
              <h3>¿Tienes una cuenta y no sabes como entrar?</h3>
              <div>Solicita una nueva clave acá:</div>
              <div class="form-group">
                <!-- <label for="exampleInputEmail1" class="form-label">Email</label> -->
                <input v-model="email" type="email" class="form-control" id="exampleInputEmail1" placeholder="ingresa tu correo acá">
              </div>
              <br>
              <button :disabled="loading" class="btn btn-primary" @click.prevent="solicitaClave">
                click acá para solicitar una nueva clave <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
              </button>
              <br>
              <router-link to="/login">Acceso usuarios</router-link>
              <br>
              <br>
              <br>
              <div class="center">NOTA: Por favor recuerda revisar bien tu correo, si no te ha llegado uno dentro de los próximos 5 minutos revisa la carpeta "no deseados" o SPAM. </div>
              <br>
              <br>
              <div class="center"><a href="https://bycyoga.cl/contacto/">Cualquier otra duda visita nuestro sitio acá</a></div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// const axios = require('axios')
const wait = timeout => (new Promise(resolve => setTimeout(resolve, timeout)))

export default {
  name: 'HelpComponent',
  data () {
    return {
      data: [],
      loading: false,
      email: '',
      password: '',
      nombre: '',
      apellido: '',
      rut: '',
      telefono: '',
      showEstudios: false,
      createUser: false,
      showWarning: false,
      showWarningText: ''
    }
  },
  mounted () {},
  methods: {
    async solicitaClave () {
      const self = this
      self.loading = true
      const { email } = self
      try {
        const result = await self.$store.dispatch('doReset', { email: email ? email.toString().toLowerCase().trim() : '' })
        console.log('>>> resultado', result)
        await wait(1000)
        // await wait(1000 * 3)
        if (result && result.result === true) {
          window.alert('Por favor revisa tu correo (si no te llega en 5 minutos revisa la carpeta no deseados por favor)')
        } else {
          self.showWarning = true
          self.showWarningText = '<br>Hubo un problema al intentar solicitar una nueva clave, recuerda ingresar tu email, todo en minúsculas.'
        }
        self.loading = false
      } catch (e) {
        self.loading = false
      }
      // self.loading = false
    },
    async verifyAccount () {
      const self = this
      self.loading = true
      const { email } = self
      try {
        const result = await self.$store.dispatch('doVerify', { email: email ? email.toString().toLowerCase().trim() : '' })
        console.log('>>> resultado', result)
        await wait(1000)
        // await wait(1000 * 3)
        if (result && result.result === true) {
          window.alert('Tu cuenta existe!')
        } else {
          window.alert('Tu cuenta no existe pero no hay problema, puedes crear una')
          self.showWarning = true
          self.showWarningText = '<br>Hubo un problema al hacer la consulta para el correo ' + email + ''
        }
        self.loading = false
      } catch (e) {
        self.loading = false
      }
      // self.loading = false
    }
  }
}
</script>
