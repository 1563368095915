<template>
  <div v-if="loadingMisClases" class="center">
    <i class="fas fa-circle-notch fa-spin"></i> cargando mis clases...
    <!-- <br> -->
    <!-- <br> -->
    <!-- <br> -->
    <!-- <br> -->
  </div>
  <template v-else>
    <div v-if="dataMisClases.length > 0">
      <div class="col-sm-12 col-xl-8 offset-xl-2">
        <table class="table table-sm table-nowrap">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Box</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="clase of dataMisClases" v-bind:key="clase._id">
              <td>{{formatDate(clase)}}</td>
              <td>{{getBoxName(clase)}}</td>
              <td>
                <div v-if="isCurrent(clase)" class="btn btn-warning btn-sm" @click.prevent="cancelClass(clase._id)">cancelar</div>
                <div v-else></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="center">No tienes ninguna clase todavía.<br></div>
  </template>
  <!-- <div class="center">¿Quieres comprar una nueva clase? <router-link to="/buscar-clases">Búscala acá</router-link></div> -->
  <!-- <br> -->
  <!-- <div class="center">¿Quieres practicar en otro estudio? <router-link :to="{ name: 'Estudios', query: { showEstudios: true }}">Selecciona tu estudio acá</router-link></div> -->
  <br>
</template>

<script>
import axios from 'axios'
const moment = require('moment-timezone')

// de utils
/**
 * formatear fecha en elapsed time o remaining
 */
const formatDateChileElapsed = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').fromNow()
}
const formatDateChileDate = function (date, locale = 'en') {
  locale = 'es' // @todo desactivar esta línea
  moment.locale(locale)
  return moment.tz(date, 'America/Santiago').format('dddd D MMMM, YYYY - H:mm')
  // return moment.tz(date, 'America/Santiago').format('D MMMM, YYYY h:mm:ss a')
  // return moment.tz(date, 'America/Santiago').format('D MMMM, YYYY')
}
const formatDateChileFull = function (temp) {
  return formatDateChileDate(temp) + ' (' + formatDateChileElapsed(temp) + ')'
}
const isInTheFuture = function (temp) {
  return (new Date(temp).getTime() - Date.now() > 0)
}

export default {
  data () {
    return {
      flatpickr: {},
      isValid: false,
      isInvalid: false,
      message: 'nada todavía',
      wasValidated: false,
      fecha: '',
      dataClasesPosibles: [],
      dataMisClases: [],
      loading: false,
      showError: false,
      loadingMisClases: false,
      appsBycyogaClaseInstanceId: null,
      appsBycyogaTarifaId: null,
      boxNumber: null,
      precioSpot: null,
      showPast: this.$route.query.showPast || false
    }
  },
  mounted () {
    const self = this
    self.loadMisClases()
  },
  methods: {
    isCurrent (clase) {
      const { computed } = clase
      if (computed) {
        const { appsBycyogaClaseInstance } = computed
        if (appsBycyogaClaseInstance) {
          const { date } = appsBycyogaClaseInstance
          if (date) return isInTheFuture(date)
        }
      }
      return false
    },
    formatDate (clase) {
      return formatDateChileFull((clase.computed && clase.computed.appsBycyogaClaseInstance) ? clase.computed.appsBycyogaClaseInstance.date : '')
    },
    getBoxName (clase) {
      if (clase.computed && clase.computed.appsBycyogaBox) return clase.computed.appsBycyogaBox.text
      return ''
    },
    async cancelClass (appsBycyogaClaseBoxId) {
      const message = `Seguro quieres cancelar esta clase?

Si las condiciones de la tarifa con que fue comprada lo permiten, se abonará el valor correspondiente a tu cuenta.`
      if (window.confirm(message)) {
        const self = this
        self.loadingMisClases = true
        const token = localStorage.getItem('bycyoga-jwt')
        let headers = {}
        if (token && token.length) headers = { Authorization: `Bearer ${token}` }
        const cancelResult = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'cancel-class', payload: { appsBycyogaClaseBoxId } }, { headers })
        const { data } = cancelResult
        if (data) {
          if (data.result === true) {
            window.alert('Tu clase fue anulada y abonada a tu saldo')
          } else {
            window.alert('Hubo un problema al anular tu clase\n\n' + data.errMsg)
          }
        }
        self.loadMisClases()
      }
    },
    async loadMisClases () {
      const self = this
      self.$store.commit('setError', false)
      self.loadingMisClases = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      try {
        const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'mis-clases' }, { headers })
        if (response && response.data && response.data.data) self.dataMisClases = response.data.data
      } catch (e) {
        console.log('>>> fail1', e.message)
        self.$store.commit('setError', true)
      }
      self.loadingMisClases = false
    },
    seleccionarClase (appsBycyogaClaseInstanceId) {
      this.appsBycyogaClaseInstanceId = appsBycyogaClaseInstanceId
    },
    seleccionarTarifa (appsBycyogaTarifaId, precioSpot) {
      this.appsBycyogaTarifaId = appsBycyogaTarifaId
      this.precioSpot = precioSpot
    },
    loadClasesDisponibles () {
      const self = this
      self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      axios
        .post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'buscar-clases', payload: { fecha: self.fecha } }, { headers })
        .then(response => {
          self.loading = false
          if (response && response.data && response.data.data) self.dataClasesPosibles = response.data.data
          self.loading = false
        })
        .catch(e => {
          self.loading = false
          self.showBackToLogin = true
          setTimeout(() => {
            alert('Hubo un problema al cargar tus datos')
          }, 600)
        })
    },
    async addToCart () {
      const self = this
      const { appsBycyogaClaseInstanceId, appsBycyogaTarifaId, boxNumber, precioSpot } = self
      // self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }
      await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy', { action: 'add-to-cart', payload: { appsBycyogaClaseInstanceId, appsBycyogaTarifaId, boxNumber, precioSpot } }, { headers })
      self.appsBycyogaClaseInstanceId = null
      self.appsBycyogaTarifaId = null
      self.boxNumber = null
      self.loading = false
    }
  },
  watch: {}
}
</script>
