<template>
  <div class="container">
    <div class="row">
      <div class="offset-lg-4 col-lg-4">
        <div class="card">
          <div class="card-body">
            <form>
              <h3>Cambio de clave</h3>
              <!-- <div class="form-group">
                <label for="exampleInputEmail1" class="form-label">Token</label>
                <input v-model="params.token" type="email" class="form-control" id="exampleInputEmail1" placeholder="token">
              </div> -->
              <div class="form-group">
                <!-- <label for="exampleInputPassword1" class="form-label">Nueva Clave</label> -->
                <input v-model="password" type="password" class="form-control" id="exampleInputPassword1" placeholder="escribe tu nueva clave aquí">
              </div>
              <br>
              <button :disabled="loading" class="btn btn-primary" @click.prevent="passwordChange">
                cambiar mi clave <span v-if="loading" ><i class="fas fa-circle-notch fa-spin"></i></span>
              </button>
              <br>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- success -->
  <div class="container center" v-if="transactionResult && query.resultado === 'success'">
    <div class="row" style="">
      <div class="col"><h1 class="success">Pago exitoso.</h1><br>A continuación los detalles de la transacción:<br><br></div>
    </div>
    <div class="row" style="">
      <div class="col"><span class="bold">Monto:</span> ${{transactionResult.amount}}</div>
    </div>
    <div class="row">
      <div class="col"><span class="bold">Estado:</span> {{transactionResult.status}}</div>
    </div>
    <div class="row" v-if="transactionResult.card_detail">
      <div class="col"><span class="bold">Tarjeta terminada en:</span> {{transactionResult.card_detail.card_number}}</div>
    </div>
    <div class="row">
      <div class="col"><span class="bold">Fecha:</span> {{transactionResult.transaction_date}}</div>
      <!-- <div class="col"><span class="bold">Fecha:</span> {{new Date(transactionResult.transaction_date)}}</div> -->
    </div>
    <div class="row">
      <div class="col"><span class="bold">Código de autorización:</span> {{transactionResult.authorization_code}}</div>
    </div>
  </div>
  <!-- <div v-if="query.buyOrder">Buy order: {{query.buyOrder}}</div> -->
  <div v-if="query.data">Data: {{query.data}}</div>
</template>

<script>
const axios = require('axios')
// const moment = require('moment-timezone')

export default {
  name: 'FinTransaccion',
  props: {
    msg: String
  },
  data () {
    return {
      VUE_APP_API_SERVER: process.env,
      data: [],
      loading: false,
      showBackToLogin: false,
      query: {},
      params: {},
      password: null,
      transactionResult: {}
    }
  },
  mounted () {
    // this.loadAuthContent()
    console.log('>>> here', this.$route.query)
    this.query = this.$route.query
    this.params = this.$route.params
    // if (this.query.resultado === 'success') this.loadBuyOrderData()
  },
  computed: {},
  methods: {
    async passwordChange () {
      const self = this
      self.loading = true
      const token = localStorage.getItem('bycyoga-jwt')
      let headers = {}
      if (token && token.length) headers = { Authorization: `Bearer ${token}` }

      const response = await axios.post(process.env.VUE_APP_API_SERVER + '/service-proxy-open', { action: 'password-change-do', payload: { token: self.params.token, password: self.password } }, { headers })
      if (response && response.data && response.data.result === true) {
        // ok cambio de clave
        window.alert('Clave cambiada existosamente por favor vuelva a entrar!')
        this.$router.push({ name: 'Home' })
      } else {
        window.alert('No se pudo cambiar la clave')
      }
      self.loading = false
    }
  }
}
</script>
<style>
.bold {
  font-weight: 600;
 }
</style>
