<template>
  <div class="row">
    <div class="col-12">
      <template v-if="estudio === 'BYCYOGA_Tobalaba'">
        <div class="row">
          <div class="col-2 box-tipo" style="border-bottom: 0px;border-right: 0px;">&nbsp;</div>
          <div class="col-1 box-tipo" style="border-top: 0px;border-bottom: 0px;border-right: 0px;border-left:0px">&nbsp;</div>
          <div class="col-2 box-tipo" style="border-bottom: 0px;border-right: 0px;border-left: 0px;">&nbsp;</div>
          <div class="col-1 box-tipo" style="border-left: 0px;border-bottom: 0px;border-right: 0px;">P</div>
          <div class="col-4 box-tipo" style="border-bottom: 0px;border-right: 0px;border-left: 0px;">&nbsp;</div>
          <!-- <div class="col-1 box-tipo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 5)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 6)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 7)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 8)}}</div> -->
          <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 9)}}</div>

<!--      <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 10)}}</div>
          <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 6)}}</div>
          <div class="col-1 box-tipo" style="border-bottom: 0px; border-top: 0px;"></div>
          <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 5)}}</div>
          <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 4)}}</div>
          <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 3)}}</div>
          <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 2)}}</div>
          <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 1)}}</div> -->
          <!-- <div class="col-10 box-tipo" style="border-top: 0px; border-right: 0px;">Sala Libre&nbsp;</div> -->
          <!-- <div class="col-1 box-tipo" style="border-top: 0px; border-bottom: 0px;border-left: 0px;">&nbsp;</div> -->
        </div>
        <div class="row">
          <div class="col-11 col-middle"></div>
        </div>
        <div class="row">
<!--           <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 6)}}</div>
          <div class="col-1 box-tipo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 5)}}</div>
-->
          <div class="col-10 box-tipo" style="border-top: 0px;border-left:0px;border-bottom: 0px;">&nbsp;</div>
          <!-- <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 5)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 2)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 1)}}</div> -->
          <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 0)}}</div>
<!--           <div class="col-1 box-tipo" :class="clase(16)" @click.prevent="select(16)">{{printBox(16, 4)}}</div>
          <div class="col-1 box-tipo" :class="clase(17)" @click.prevent="select(17)">{{printBox(17, 3)}}</div>
          <div class="col-1 box-tipo" :class="clase(18)" @click.prevent="select(18)">{{printBox(18, 2)}}</div>
          <div class="col-1 box-tipo" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 1)}}</div>
 -->
           <!-- <div class="col-1 box-tipo" style="border-bottom: 0px; border-top: 0px;"></div> -->
        </div>
        <div class="row row-bottom">
          <div class="col-11 col-gruesa" style="border-top: 0px;"></div>
        </div>
      </template>
      <template v-if="estudio === 'BYCYOGA_Puertomontt'">
        <div class="row row-top">
          <div class="col-12 col-delgada"></div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 10)}}</div>
          <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 8)}}</div>
          <!-- <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 7)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 6)}}</div> -->
          <div class="col-1 offset-sm-2 box-tipo" style="border: 0px;border-right: 0px;">P</div>
          <!-- <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 5)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 2)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 1)}}</div> -->
          <!-- <div class="col-1 box-tipo" style="border-bottom: 0px; border-right:0px;"></div> -->
        </div>
        <div class="row">
          <div class="col-12 col-middle"></div>
        </div>
        <div class="row">
          <!-- <div class="col-11 box-tipo" style="border-bottom:0px; border-right: 0px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Sala Libre</div> -->
          <!-- <div class="col-1 box-tipo" style="border-bottom:0px; border-right: 0px;">&nbsp;</div> -->
          <!-- <div class="col-2 box-tipo" style="border-bottom:0px; border-right: 0px;">&nbsp;</div> -->
          <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 10)}}</div>
          <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 8)}}</div>
          <!-- <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 7)}}</div> -->
          <div class="col-9 box-tipo" style="border-top: 0px; border-bottom: 0px;">&nbsp;</div>
          <!-- <div class="col-1 box-tipo" :class="clase(16)" @click.prevent="select(16)">{{printBox(16, 5)}}</div> -->
          <!-- <div class="col-1 box-tipo" style="border-top: 0px;border-bottom: 0px;"></div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(17)" @click.prevent="select(17)">{{printBox(17, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(18)" @click.prevent="select(18)">{{printBox(18, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 2)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(20)" @click.prevent="select(20)">{{printBox(20, 1)}}</div> -->
          <!-- <div class="col-1 box-tipo" style="border-bottom: 0px; border-top: 0px;"></div> -->
        </div>
        <div class="row row-bottom">
          <div class="col-8 col-gruesa" style="border-right: 0px;"></div>
          <div class="col-1 col-gruesa" style="border-left: 0px; border-right: 0px; border-bottom: 0px;"></div>
          <div class="col-3 col-gruesa" style="border-left: 0px; "></div>
        </div>
      </template>
      <template v-if="estudio === 'BYCYOGA_Puertovaras'">
        <div class="row row-top">
          <div class="col-11 col-delgada"></div>
        </div>
        <div class="row">
          <!-- <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 1)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 2)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 5)}}</div> -->
          <div class="col-8 box-tipo" style="border-bottom: 0px;border-right: 0px;">&nbsp;</div>
          <div class="col-1 box-tipo" style="border-bottom: 0px;border-left: 0px;">P</div>
          <!-- <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 6)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 7)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 8)}}</div> -->
          <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</div>
        </div>
        <div class="row">
          <div class="col-11 col-middle"></div>
        </div>
        <div class="row">
           <div class="col-9 box-tipo" style="border-bottom: 0px; border-top:0px;border-right: 0px;">&nbsp;</div>
           <!-- <div class="col-1 box-tipo" style="border-bottom: 0px; border-top:0px;border-right: 0px;">&nbsp;</div> -->
           <!-- <div class="col-9 box-tipo" style="border-bottom: 0px; border-left:0px; border-right: 0px;">Sala Libre</div> -->
           <!-- <div class="col-1 box-tipo" style="border-bottom: 0px; border-top:0px;border-left: 0px;">&nbsp;</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 1)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 2)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" style="border-top: 0px;border-bottom: 0px;border-right:0px"></div> -->
          <!-- <div class="col-1 box-tipo" style="border-top: 0px;border-bottom: 0px;border-left: 0px"></div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 5)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(16)" @click.prevent="select(16)">{{printBox(16, 6)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(17)" @click.prevent="select(17)">{{printBox(17, 7)}}</div> -->
          <div class="col-1 box-tipo" :class="clase(18)" @click.prevent="select(18)">{{printBox(18, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 9)}}</div>
          <!-- <div class="col-1 box-tipo" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 9)}}</div> -->
        </div>
        <div class="row row-bottom">
          <div class="col-2 col-gruesa" style="border-right: 0px; "></div>
          <div class="col-1 col-gruesa" style="border-left: 0px; border-right: 0px; border-bottom: 0px;"></div>
          <div class="col-5 col-gruesa" style="border-left: 0px; border-right: 0px; "></div>
          <div class="col-1 col-gruesa" style="border-left: 0px; border-right: 0px; border-bottom: 0px;"></div>
          <div class="col-2 col-gruesa" style="border-left: 0px; "></div>
        </div>
      </template>
      <template v-if="estudio === 'BYCYOGA_Vitacura' && $store?.state?.enableEspaciosVitacura === true">
        <!-- <div class="row">
          <div class="col"><span class="badge bg-warning text-dark">los espacios numerados tienen un descuento de un 10%<br />que se aplicará al momento de pagar en Transbank</span></div>
        </div> -->
        <div class="row row-top">
          <div class="col-12 col-delgada d-none d-md-block"></div>
        </div>
        <template v-if="true">
          <!-- caso mobile -->
          <div class="row d-md-none">
            <div class="col-12">
              <table class="table">
                <tr>
                  <td class="box-tipo box-mobile-test" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 1)}}</td>
                  <td class="box-tipo box-mobile-test" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 2)}}</td>
                  <td
                    class="col box-espacio-nuevo-mini box-mobile-test"
                    v-for="i in [21,22,23,24,25,26,27,28,29,30]"
                    v-bind:key="i"
                    :class="clase(i)"
                    @click.prevent="select(i)"
                  >{{printBox(i, 'E' + (i - 20))}}<br /><span class="box-espacio-nuevo-descuento">&nbsp;</span></td>
                  <td class="box-tipo box-mobile-test" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</td>
                  <td class="box-tipo box-mobile-test" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</td>
                </tr>
                <tr>
                  <td
                    class="box-tipo box-mobile-test"
                    :class="clase(48 + 1)"
                    @click.prevent="select(48 + 1)"
                  >{{printBox(48 + 1, 'E' + (48 + 1 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                  </td>
                  <td
                    class="box-tipo box-mobile-test"
                    :class="clase(48 + 2)"
                    @click.prevent="select(48 + 2)"
                  >{{printBox(48 + 2, 'E' + (48 + 2 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                  </td>
                  <td
                    class="col box-espacio-nuevo-mini box-mobile-test"
                    v-for="i in [31,32,33,34,35,36,37,38,39,40]"
                    v-bind:key="i"
                    :class="clase(i)"
                    @click.prevent="select(i)"
                  >{{printBox(i, 'E' + (i - 20))}}<br /><span class="box-espacio-nuevo-descuento">&nbsp;</span></td>

                  <td
                    class="box-tipo box-mobile-test"
                    :class="clase(48 + 3)"
                    @click.prevent="select(48 + 3)"
                  >{{printBox(48 + 3, 'E' + (48 + 3 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                  </td>
                  <td
                    class="box-tipo box-mobile-test"
                    :class="clase(48 + 4)"
                    @click.prevent="select(48 + 4)"
                  >{{printBox(48 + 4, 'E' + (48 + 4 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                  </td>
                </tr>
                <tr>
                  <td class="box-mobile-test box-tipo box-mobile-test-left">&nbsp;</td>
                  <td class="box-mobile-test box-tipo box-mobile-test-none">&nbsp;</td>
                  <td
                    class="col box-espacio-nuevo-mini box-mobile-test"
                    v-for="i in [41,42,43,44]"
                    v-bind:key="i"
                    :class="clase(i)"
                    @click.prevent="select(i)"
                  >{{printBox(i, 'E' + (i - 20))}}<br /><span class="box-espacio-nuevo-descuento">&nbsp;</span></td>
                  <td class="box-mobile-test">&nbsp;</td>
                  <td class="box-mobile-test">&nbsp;</td>
                  <td
                    class="col box-espacio-nuevo-mini box-mobile-test"
                    v-for="i in [45,46,47,48]"
                    v-bind:key="i"
                    :class="clase(i)"
                    @click.prevent="select(i)"
                  >{{printBox(i, 'E' + (i - 20))}}<br /><span class="box-espacio-nuevo-descuento">&nbsp;</span></td>
                  <td class="box-mobile-test box-tipo box-mobile-test-none">&nbsp;</td>
                  <td class="box-mobile-test box-tipo box-mobile-test-right">&nbsp;</td>
                </tr>
                <tr style="border: 0px;">
                  <td colspan=14 class="col-delgada" style="border-top: 0px;">&nbsp;</td>
                </tr>
              </table>
            </div>
          </div>
        </template>
        <template v-if="true">
          <div class="row">
            <!-- caso pantalla normal  -->
            <div class="col-2 d-none d-md-block">
              <div class="row">
                <div class="col-6 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 1)}}</div>
                <div class="col-6 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 2)}}</div>
              </div>
              <div class="row">
                <div
                  class="col-6 box-tipo box-espacio-nuevo-mini-2"
                  :class="clase(48 + 1)"
                  @click.prevent="select(48 + 1)"
                >{{printBox(48 + 1, 'E' + (48 + 1 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                </div>
                <div
                  class="col-6 box-tipo box-espacio-nuevo-mini-2"
                  :class="clase(48 + 2)"
                  @click.prevent="select(48 + 2)"
                >{{printBox(48 + 2, 'E' + (48 + 2 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                </div>
              </div>
            </div>
            <div class="col-8 d-none d-md-block">
              <div class="row" v-for="elementos in [[21,22,23,24,25,26,27,28,29,30], [31,32,33,34,35,36,37,38,39,40], [41,42,43,44,45,46,47,48]]" v-bind:key="elementos">
                <div class="col-1 d-md-none">&nbsp;</div>
                <template v-for="i in elementos" v-bind:key="i">
                  <template v-if="i === 45">
                    <div class="col d-none d-md-block">&nbsp;</div>
                    <div class="col d-none d-md-block">&nbsp;</div>
                  </template>
                  <template v-if="i === 45">
                    <div class="col-2 d-md-none">&nbsp;</div>
                  </template>
                  <div
                    class="col-1 box-espacio-nuevo-mini d-blockQ d-md-none"
                    :class="clase(i)"
                    @click.prevent="select(i)"
                  >{{printBox(i, 'E' + (i - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                  </div>
                  <div
                    class="col box-espacio-nuevo d-none d-md-block"
                    :class="clase(i)"
                    @click.prevent="select(i)">{{printBox(i, 'E' + (i - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-2 d-none d-md-block">
              <div class="row">
                <div class="col-6 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</div>
                <div class="col-6 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</div>
              </div>
              <div class="row">
                <div
                  class="col-6 box-tipo box-espacio-nuevo-mini-2"
                  :class="clase(48 + 3)"
                  @click.prevent="select(48 + 3)"
                >{{printBox(48 + 3, 'E' + (48 + 3 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                </div>
                <div
                  class="col-6 box-tipo box-espacio-nuevo-mini-2"
                  :class="clase(48 + 4)"
                  @click.prevent="select(48 + 4)"
                >{{printBox(48 + 4, 'E' + (48 + 4 - 20))}}<br /><span class="box-espacio-nuevo-descuento-desktop">&nbsp;</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row row-bottom d-none d-md-block">
            <div class="col-12 col-gruesa"></div>
            <br />
          </div>
        </template>
        <div class="row">
          <div class="col"><span class="badge bg-warning text-dark">el layout puede variar de acuerdo a la resolución de tu pantalla<br />por favor confirmar posición exacta en el estudio</span></div>
        </div>
      </template>

      <template v-if="estudio === 'BYCYOGA_Vitacura' && $store?.state?.enableEspaciosVitacura !== true">
        <div class="row row-top">
          <div class="col-11 col-delgada"></div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 1)}}</div>
          <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 2)}}</div>
          <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 3)}}</div>
          <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 4)}}</div>
          <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 5)}}</div>
          <div class="col-1 box-tipo" style="border-bottom: 0px;">P</div>
          <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 6)}}</div>
          <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</div>
        </div>
        <div class="row">
          <div class="col-11 col-middle"></div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 1)}}</div>
          <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 2)}}</div>
          <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 3)}}</div>
          <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 4)}}</div>
          <div class="col-1 box-tipo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 5)}}</div>
          <div class="col-1 box-tipo" style="border-top: 0px;border-bottom: 0px;"></div>
          <div class="col-1 box-tipo" :class="clase(16)" @click.prevent="select(16)">{{printBox(16, 6)}}</div>
          <div class="col-1 box-tipo" :class="clase(17)" @click.prevent="select(17)">{{printBox(17, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(18)" @click.prevent="select(18)">{{printBox(18, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(20)" @click.prevent="select(20)">{{printBox(20, 10)}}</div>
        </div>
        <div class="row row-bottom">
          <div class="col-11 col-gruesa"></div>
        </div>
      </template>

      <template v-if="estudio === 'BYCYOGA_Quinchamali'">
        <div class="row row-top">
          <div class="col-11 col-delgada"></div>
        </div>
        <div class="row" style="border-right: 10px;">
          <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 'A1')}}</div>
          <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 'A2')}}</div>
          <!-- <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 5)}}</div> -->
          <div class="col-4 box-tipo" style="border: 0px;">P</div>
          <div class="col-5 box-tipo" style="border-left: 0px;border-bottom: 0px;border-top: 0px;">&nbsp;</div>
<!--           <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 6)}}</div>
          <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</div>
-->
        </div>
        <div class="row">
          <div class="col-11 col-middle">
            <div class="row">
              <div class="col-6"></div>
              <div class="col-5 box-tipo" style="border: 0px;">Sala Libre</div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 'B1')}}</div>
          <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 'B2')}}</div>
          <!-- <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 3)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 4)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 5)}}</div> -->
          <div class="col-4 box-tipo" style="border: 0px;border-bottom: 0px;"></div>
          <div class="col-5 box-tipo" style="border-left: 0px;border-bottom: 0px;border-top: 0px;">&nbsp;</div>
<!--           <div class="col-1 box-tipo" :class="clase(16)" @click.prevent="select(16)">{{printBox(16, 6)}}</div>
          <div class="col-1 box-tipo" :class="clase(17)" @click.prevent="select(17)">{{printBox(17, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(18)" @click.prevent="select(18)">{{printBox(18, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(20)" @click.prevent="select(20)">{{printBox(20, 10)}}</div>
 -->
        </div>
        <div class="row row-bottom" style="height: 300px">
          <div class="col-2 col-gruesa col-quinchamali" style="border-right: 0px;"></div>
          <div class="col-1 col-gruesa col-quinchamali" style="border-right: 0px;border-left:0px;border-bottom: 0px;"></div>
          <div class="col-3 col-gruesa col-quinchamali" style="border-right: 0px;border-left:0px;"></div>
          <div class="col-1 col-gruesa col-quinchamali" style="border-right: 0px;border-left:0px;border-bottom: 0px;"></div>
          <div class="col-4 col-gruesa col-quinchamali" style="border-left: 0px;">
            <br>Estufa a Pellet
          </div>
        </div>
      </template>
      <template v-if="estudio === 'BYCYOGA_Valdivia'">
        <div class="row row-top">
          <div class="col-10 col-delgada"></div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" style="border: 0px;border-left: 0px;border-right: 0px;">ent.</div>
          <!-- <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 1)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 2)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 3)}}</div> -->
          <div class="col-3 box-tipoQ">&nbsp;</div>
          <div class="col-1 box-tipo" style="border-bottom: 0px;border-left: 0px; border-top: 0px;">P</div>
          <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 4)}}</div>
          <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 5)}}</div>
          <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 6)}}</div>
          <div class="col-1 box-tipo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 15)}}</div>
          <div class="col-1 box-tipo" style="border-bottom: 0px;border-right: 0px;">exit</div>
        </div>
        <div class="row">
          <div class="col-10 col-middle">&nbsp;</div>
        </div>
        <div class="row">
          <!-- <div class="col-1 box-tipo" style="border-bottom: 0px;border-top: 0px; border-right: 0px;"></div> -->
          <!-- <div class="col-1 box-tipo" style="border-bottom: 0px;border-top: 0px;"></div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 7)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 8)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</div> -->
          <!-- <div class="col-1 box-tipo" style="border-top:0px;border-bottom:0px;border-right:0px">&nbsp;</div> -->
          <!-- <div class="col-8 box-tipo" style="border-bottom: 0px;border-left: 0px;border-right: 0px;">Sala Libre</div> -->
          <!-- <div class="col-1 box-tipo" style="border-left:0px;border-bottom: 0px;border-top: 0px;">&nbsp;</div> -->
          <!-- <div class="col-1 box-tipoQ">&nbsp;</div> -->
          <!-- <div class="col-1 box-tipoQ">&nbsp;</div> -->
          <!-- <div class="col-1 box-tipoQ">&nbsp;</div> -->
          <div class="col-10 box-tipo" style="border-top:0px; border-bottom:0px;">&nbsp;</div>
          <!-- <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 11)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 12)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 13)}}</div> -->
          <!-- <div class="col-1 box-tipo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 14)}}</div> -->
        </div>
        <div class="row row-bottom" style="height: 300px">
          <div class="col-1 col-gruesa col-quinchamali" style="border-right: 0px;">
            <br />
            <br />
            &nbsp;&nbsp;&nbsp;
          </div>
          <div class="col-9 col-gruesa col-quinchamali" style="border-left: 0px; text-align: center;">
            <!-- <br>Estufa a Pellet VALDIVIA -->
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </div>
        </div>
      </template>
      <template v-if="estudio === 'BYCYOGA_Nunoa'">
        <div class="row row-top">
          <div class="col-12 col-delgada"></div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" :class="clase(1)" @click.prevent="select(1)">{{printBox(1, 1)}}</div>
          <div class="col-1 box-tipo" :class="clase(2)" @click.prevent="select(2)">{{printBox(2, 2)}}</div>
          <div class="col-1 box-tipo" :class="clase(3)" @click.prevent="select(3)">{{printBox(3, 3)}}</div>
          <div class="col-1 box-tipo" :class="clase(4)" @click.prevent="select(4)">{{printBox(4, 4)}}</div>
          <div class="col-1 box-tipo" :class="clase(5)" @click.prevent="select(5)">{{printBox(5, 5)}}</div>
          <div class="col-1 box-tipo" :class="clase(6)" @click.prevent="select(6)">{{printBox(6, 6)}}</div>
          <div class="col-1 box-tipo" style="border-bottom: 0px;">P</div>
          <div class="col-1 box-tipo" :class="clase(7)" @click.prevent="select(7)">{{printBox(7, 7)}}</div>
          <div class="col-1 box-tipo" :class="clase(8)" @click.prevent="select(8)">{{printBox(8, 8)}}</div>
          <div class="col-1 box-tipo" :class="clase(9)" @click.prevent="select(9)">{{printBox(9, 9)}}</div>
          <div class="col-1 box-tipo" :class="clase(10)" @click.prevent="select(10)">{{printBox(10, 10)}}</div>
          <div class="col-1 box-tipo" :class="clase(11)" @click.prevent="select(11)">{{printBox(11, 11)}}</div>
        </div>
        <div class="row">
          <div class="col-12 col-middle" style="height: 100px;"></div>
        </div>
        <div class="row">
          <div class="col-1 box-tipo" style="border-bottom: 0px; border-top: 0px;border-right: 0px;padding-top: 30px"></div>
          <div class="col-1 box-tipo" style="border-bottom: 0px; border-top: 0px;border-left: 0px;padding-top: 30px;border-right:0px"></div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(12)" @click.prevent="select(12)">{{printBox(12, 1)}}</div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(13)" @click.prevent="select(13)">{{printBox(13, 2)}}</div>
          <div class="col-1 box-tipo box-nunoa-abajo" :class="clase(14)" @click.prevent="select(14)">{{printBox(14, 3)}}</div>
          <div class="col-1 box-tipo box-nunoa-abajo" :class="clase(15)" @click.prevent="select(15)">{{printBox(15, 4)}}</div>
          <div class="col-1 box-tipo" style="border-top: 0px;border-bottom: 0px;border-left:0px;border-right:0px"></div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(16)" @click.prevent="select(16)">{{printBox(16, 5)}}</div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(17)" @click.prevent="select(17)">{{printBox(17, 6)}}</div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(18)" @click.prevent="select(18)">{{printBox(18, 7)}}</div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(19)" @click.prevent="select(19)">{{printBox(19, 8)}}</div>
          <div class="col-1 box-tipo box-nunoa-arriba" :class="clase(20)" @click.prevent="select(20)">{{printBox(20, 9)}}</div>
        </div>
        <div class="row row-bottom">
          <div class="col-12 col-middle" style="height: 100px;"></div>
          <div class="col-12 col-gruesa"></div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  name: 'boxesComponent',
  props: ['boxes', 'estudio'],
  emits: ['boxSeleccionado'],
  data () {
    return {
      data: [],
      boxSeleccionado: null,
      boxesCopy: []
      // boxesSIMULADOS: [{ numero: 1, usado: true, text: 'B10' }, { numero: 2, usado: false, text: 'B9' }, { usado: false, numero: 3, text: 3 }, { usado: false, numero: 4, text: 4 }, { usado: false, numero: 5, text: 5 }, { usado: false, numero: 6, text: 6 }, { usado: false, numero: 7, text: 7 }, { usado: false, numero: 8, text: 8 }, { usado: false, numero: 9, text: 9 }, { usado: false, numero: 10, text: 10 }, { usado: false, numero: 11, text: 11 }, { usado: false, numero: 12, text: 12 }, { usado: false, numero: 13, text: 13 }, { usado: false, numero: 14, text: 14 }, { usado: false, numero: 15, text: 15 }, { usado: false, numero: 16, text: 16 }, { usado: false, numero: 17, text: 17 }, { usado: false, numero: 18, text: 18 }, { usado: false, numero: 19, text: 19 }]
    }
  },
  mounted () {
    // esto no estea funcionando pues boxes tiene un
    // valor despues, habría que probar con watch
    // this.generateLayout()
  },
  methods: {
    printBox (boxNumber, defaultNumber) {
      let out = defaultNumber || 'X'
      const { boxes } = this
      if (boxes && Array.isArray(boxes)) {
        if (boxes[boxNumber - 1] && boxes[boxNumber - 1].text) {
          out = boxes[boxNumber - 1].text
        }
      }
      return out
    },
    select (boxNumber) {
      let found = false
      this.boxes.forEach((box, index) => {
        if (box && box.numero === boxNumber && box.usado !== true) found = true
      })
      if (found) {
        this.boxes.forEach((box, index) => {
          if (box && box.numero === boxNumber && box.usado !== true) {
            this.boxesCopy[index].seleccionado = true
            this.boxSeleccionado = this.boxes[index].numero
            this.$emit('boxSeleccionado', this.boxSeleccionado)
          } else {
            this.boxesCopy[index].seleccionado = false
          }
        })
      }
    },
    clase (boxNumber) {
      if (boxNumber === 1231312312) {
        return 'box-libre'
      } else {
        const boxes = this.boxes
        // const boxes = this.boxesSIMULADOS
        let libre
        let seleccionado = false
        try {
          let box = {}
          boxes.forEach(d => {
            if (d.numero === boxNumber) box = d
          })
          if (box.usado === true) libre = false
          if (box.usado === false) libre = true
          if (box.seleccionado === true) seleccionado = true
        } catch (e) {}

        if (seleccionado) return 'box-seleccionado'
        if (libre === false) return 'box-ocupado'
        if (libre === true) return 'box-libre'
        return 'box-no-data'
      }
    },
    async generateLayout () {
      const boxes = this.boxes
      // const boxes = this.boxesSIMULADOS
      // caso tobalaba
      const out = []
      for (let i = 1; i <= 19; i++) {
        // let found = false
        let data = { numero: i, text: i }
        boxes.forEach(box => {
          const { numero, usado, text } = box
          if (numero === i || numero === i + '') {
            data = Object.assign({}, { numero, text })
            if (usado === true || usado === false) data.usado = usado
          }
        })
        out.push(data)
      }
      this.data = out
    }
  },
  watch: {
    boxes () {
      // console.log('.>> boxes changed, trigger generateLayout', this.boxes)
      this.boxesCopy = Object.assign([], this.boxes)
      this.generateLayout()
    }
  }
}
</script>

<style type="text/css">
  .box-tipo {
    color: #0033A1;
    font-size: 32px;
    padding-top: 50px;
    padding-bottom: 50px;
    border: 3px solid #0033A1;
    font-weight: 600;
    cursor: pointer;
  }

  .box-espacio-nuevo {
    color: #0033A1;
    font-size: 16px;
/*    font-size: 32px;*/
    padding-top: 30px;
    padding-bottom: 30px;
/*    border: 3px solid #0033A1;*/
    border: 2px solid #0033A1;
    font-weight: 600;
    cursor: pointer;
  }

  .box-espacio-nuevo-mini {
    color: #0033A1;
    font-size: 10px;
/*    font-size: 32px;*/
    padding-top: 20px;
    padding-bottom: 20px;
/*    border: 3px solid #0033A1;*/
    border: 3px solid #0033A1;
/*    border-right: 0px solid #0033A1;*/
/*    border-bottom: 1px solid #0033A1;*/
    font-weight: 600;
    cursor: pointer;
  }

  .box-espacio-nuevo-mini-2 {
    color: #0033A1;
    font-size: 20px;
    /* padding-top: 20px; */
    /* padding-bottom: 20px; */
    border: 3px solid #0033A1;
    font-weight: 600;
    cursor: pointer;

    /* font-size: 32px; */
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .box-espacio-nuevo-descuento {
    font-size: 6px;
/*    font-weight: 200;*/
/*    color: #ffc107;*/
  }
  .box-espacio-nuevo-descuento-desktop {
    font-size: 10px;
/*    font-weight: 200;*/
/*    color: #ffc107;*/
  }

  .box-mobile-test {
    width: 7%;
  }

  .box-mobile-test-left {
/*    border-left: 0px !important;*/
/*    border-right: 0px !important;*/
    border-bottom: 0px !important;
    border-top: 0px !important;
    border-right: 0px !important;
  }
  .box-mobile-test-right {
    border-bottom: 0px !important;
    border-top: 0px !important;
    border-left: 0px !important;
  }
  .box-mobile-test-none {
    border-bottom: 0px !important;
    border-top: 0px !important;
    border-left: 0px !important;
    border-right: 0px !important;
  }

  .box-seleccionado {
    background-color: #198754;
    color: white;
  }
  .box-libre {}
  .box-ocupado {
    background-color: #dc3545;
    color:  white;
    cursor: not-allowed! important;
  }

  .box-no-data {
    background-color: #e5e5f7;
    color:  #042e8c;
    cursor: not-allowed! important;
    opacity: 0.8;
    background-size: 9px 9px;
    background-image: repeating-linear-gradient(45deg, #dc3535 0, #dc3535 1px, #e5e5f7 0, #e5e5f7 50%);
  }
  .col-top {
    height: 80px;
    border-top: 3px solid #0033A1;
    border-left: 3px solid #0033A1;
    border-right: 3px solid #0033A1;
  }
  .col-middle {
    height: 80px;
    border-left: 3px solid #0033A1;
    border-right: 3px solid #0033A1;
  }
  .col-gruesa {
    height: 80px;
    border-left: 3px solid #0033A1;
    border-right: 3px solid #0033A1;
    border-bottom: 3px solid #0033A1;
  }
  .col-delgada {
    border-bottom: 3px solid #0033A1;
    border-left: 3px solid #0033A1;
    border-right: 3px solid #0033A1;
  }

  .box-nunoa-arriba {}

  /*caso especial que tiene un texto abajo*/
  .col-quinchamali {
    border-bottom: 3px solid #0033A1;
    min-height: 70px !important;
    text-align: right;
  }

  @media only screen and (max-width: 800px){
    .box-tipo {
      font-size: 14px;
      padding-top: 25px;
      padding-bottom: 25px;
      padding-left: 0px;
      padding-right: 0px;
      border: 3px solid #0033A1;
      font-weight: 400;
    }
    .col-top {
      height: 40px;
    }
    .col-middle {
      /*deprecated*/
      height: 40px;
    }
    .col-gruesa {
      height: 40px;
    }
  }
</style>
